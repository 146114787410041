import { FaUser } from "react-icons/fa6";
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Dropdown, Spinner, Tooltip } from 'flowbite-react';
import { HiArrowRightEndOnRectangle } from "react-icons/hi2";
import Menu from './Menu';
import Search from '../search/Search';
import Logout from '../dashboard/Logout';
import ShoppingCart from '../../elements/ShoppingCart';
import { getUserInfo } from '../../auth/getUserInfo';

export default function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        localStorage.setItem("username", userInfo.username);
        setRole(userInfo.role);
        setLoading(false);
      } else {
        // console.log(null);
      }
    };
    fetchUserInfo();
  }, []);

  const token = localStorage.getItem("token");
  const links = [
    {
      name: "home",
      path: "/"
    },
    {
      name: "shop",
      path: "/shop"
    },
    {
      name: "about",
      path: "/about-us"
    },
    {
      name: "Contact",
      path: "/contact-us"
    }
  ];

  return (
    <header className="w-full bg-white border-b">
      <div className='container'>
        <div className="flex items-center justify-between py-4">
          {/* Left Links */}
          <nav className="hidden md:flex gap-4 ">
            {
              links.map((item, index) => (
                <a key={index} href={item.path} className="group relative text-black w-max">
                  <span className={location.pathname === item.path ? "text-blue-600" : "text-black"}>{t(item.name)}</span>
                  <span className="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-blue-600 group-hover:w-3/6"></span>
                  <span className="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-blue-600 group-hover:w-3/6"></span>
                  {
                    location.pathname === item.path ? <span className="absolute -bottom-1 right-0 w-full transition-all h-0.5 bg-blue-600"></span> : null
                  }
                </a>
              ))
            }
          </nav>

          {/* Logo */}
          <a href="/" className="flex flex-wrap items-end text-2xl text-black lowercase font-bold">Shared<span className="text-blue-600">Store</span></a>

          {/* Right Icons */}
          <div className="hidden md:flex gap-2">
            <Search />
            {/* user : */}
            {
              !token ?
                (<Tooltip content={t("login")}>
                  <a href='/login' className="flex justify-center items-center h-9 w-9 bg-blue-600 focus:bg-blue-700 rounded-md text-white transition-all">
                    <HiArrowRightEndOnRectangle className="w-[22px] h-[22px]" />
                  </a>
                </Tooltip>
                )
                : loading ?
                  (
                    <button className="flex justify-center items-center h-9 w-9 bg-blue-600 focus:bg-blue-700 rounded-md text-white transition-all">
                      <Spinner className='w-5 h-5 text-white fill-gray-400' />
                    </button>
                  )
                  : (<Dropdown label="sign" dismissOnClick={true} renderTrigger={() => (
                    <button className="flex justify-center items-center h-9 w-9 bg-blue-600 focus:bg-blue-700 rounded-md text-white transition-all">
                      <FaUser className="w-4 h-4" />
                    </button>
                  )}>
                    {
                      role === "admin" ? (<>
                        <Dropdown.Item href='/admin/dashboard'>{t("dashboard")}</Dropdown.Item>
                        <Dropdown.Item href='/admin/settings'>{t("account")}</Dropdown.Item>
                      </>)
                        : (<>
                          <Dropdown.Item href='/account/dashboard'>{t("settings")}</Dropdown.Item>
                          <Dropdown.Item href='/account/account-details'>{t("account")}</Dropdown.Item>
                          <Dropdown.Item href='/account/favorites'>{t("favorites")}</Dropdown.Item>
                        </>)
                    }
                    <Dropdown.Item><Logout compo="text" text="Logout" /></Dropdown.Item>
                  </Dropdown>)
            }
            <ShoppingCart />
          </div>

          {/* Mobile Menu Icon */}
          <div className="md:hidden relative inline-flex">
            <ShoppingCart />
          </div>
        </div>

      </div>
      {/* Bottom Bar for Tablets */}
      <div className="fixed border-t bottom-0 flex justify-around w-full z-30 bg-white md:hidden p-3 text-blue-700">
        {
          !token ? <a href='/login'>
            <HiArrowRightEndOnRectangle className="w-6 h-6 hover:text-blue-500" />
          </a>
            : !token ?
              (<Tooltip content={t("login")}>
                <a href='/login' className="flex justify-center items-center h-9 w-9 bg-blue-600 focus:bg-blue-700 rounded-md text-white transition-all">
                  <HiArrowRightEndOnRectangle className="w-[22px] h-[22px]" />
                </a>
              </Tooltip>
              )
              : loading ? <button className="">
                <Spinner className='w-[22px] h-[22px] text-gray-400 fill-blue-600' />
              </button>
                : <Dropdown label="user" dismissOnClick={true} renderTrigger={() => (
                  <button>
                    <FaUser className="w-5 h-5 text-blue-600 hover:text-blue-700" />
                  </button>
                )}>
                  {
                    role === "admin" ? (<>
                      <Dropdown.Item href='/admin/dashboard'>{t("dashboard")}</Dropdown.Item>
                      <Dropdown.Item href='/admin/settings'>{t("account")}</Dropdown.Item>
                    </>)
                      : (<>
                        <Dropdown.Item href='/account/dashboard'>{t("settings")}</Dropdown.Item>
                        <Dropdown.Item href='/account/account-details'>{t("account")}</Dropdown.Item>
                        <Dropdown.Item href='/account/favorites'>{t("favorites")}</Dropdown.Item>
                      </>)
                  }
                  <Dropdown.Item><Logout compo="text" text="Logout" /></Dropdown.Item>
                </Dropdown>
        }
        <Search />
        <Menu />
      </div>
    </header>
  );
}
