import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import TopBar from '../ecrans/additions/TopBar';
import { CiLock } from "react-icons/ci";
import OwnToast from '../general additions/OwnToast';

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenFromUrl = params.get('token');
    const emailFromUrl = params.get('email');

    setToken(tokenFromUrl);
    setEmail(decodeURIComponent(emailFromUrl));
  }, [location]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      OwnToast('error', t("error-not-match"));
      return;
    }

    try {
      const response = await axios.post(localhost + '/reset-password', { email, password, password_confirmation: confirmPassword, token });
      console.log(response.data);
      OwnToast('success', t('s-up-psw'));
      setTimeout(() => {
        navigate('/login')
      }, 1000);
    } catch (err) { OwnToast('error', t('e-up-psw')); }
  };

  return (
    <section className="bg-gray-100 min-h-screen">
      <TopBar />
      <div className="flex flex-col items-center justify-center p-4 mx-auto h-[85vh] lg:py-0">
        <div className="w-full p-6 shadow-sm myborder bg-white rounded-lg md:mt-0 sm:max-w-md">
          <div className='flex justify-center w-full'>
            <div className='border-[2px] border-blue-600 rounded-full p-3'>
              <CiLock className='h-16 w-16 text-blue-600' />
            </div>
          </div>

          <div className='text-center mt-4 px-6'>
            <h1 className="mb-1 text-base leading-tight tracking-tight text-black">{t("reset-password-title")}</h1>
            <p className="font-light text-sm text-gray-500">{t("reset-password-text")}</p>
          </div>

          <form onSubmit={handleResetPassword} className="mt-4 space-y-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">{t("new-password")}</label>
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder={t("new-password")} required />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-black">{t("conf-password")}</label>
              <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder={t("conf-password")} required />
            </div>
            <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t("reset-password")}</button>
          </form>
        </div>
      </div>
    </section>
  )
}
