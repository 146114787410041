import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Drawer, Dropdown } from "flowbite-react";
import { Route, Routes, useNavigate } from "react-router";

import Logout from "../../ecrans/dashboard/Logout";
import DashboardMain from "./DashboardMain";
import ProductList from "./ProductList";
import CategoriesList from "./CategoriesList";
import LicensesList from "./LicensesList";
import Cproduct from "../Cproduct";
import Uproduct from "../Uproduct";
import OrdersList from "./OrdersList";
import UsersList from "./UsersList";
import CouponsList from "./CouponsList";
import DiscountsList from "./DiscountsList";

import { FaBars } from "react-icons/fa6";
import { FiHome } from "react-icons/fi";
import { GrCart } from "react-icons/gr";
import { LuUsers } from "react-icons/lu";
import { BiMoney } from "react-icons/bi";
import { RiCoupon3Line } from "react-icons/ri";
import { MdOutlineDiscount } from "react-icons/md";
import { BsAward } from "react-icons/bs";
import { LiaCubesSolid } from "react-icons/lia";
import { AiOutlineProduct } from "react-icons/ai";
import { HiUserCircle } from "react-icons/hi";
import { TbMessageReport } from "react-icons/tb";
import { Link } from "react-router-dom";
import TransactionsList from "./TransactionsList";
import ChangeLang from "../../general additions/ChangeLang";
import ReportsList from "./ReportsList";
import ReportView from "./views/ReportView";


export default function SidebarLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const dashSections = [
    {
      icon: <FiHome className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "dashboard",
      active: "",
    },
    {
      icon: <AiOutlineProduct className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "products",
      active: "products",
    },
    {
      icon: <LiaCubesSolid className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "categories",
      active: "categories",
    },
    {
      icon: <BsAward className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "licenses",
      active: "licenses",
    },
    {
      icon: <GrCart className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "orders",
      active: "orders",
    },
    {
      icon: <LuUsers className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "customers",
      active: "users",
    },
    {
      icon: <BiMoney className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "Transactions",
      active: "transactions",
    },
    {
      icon: <RiCoupon3Line className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "coupons",
      active: "coupons"
    },
    {
      icon: <MdOutlineDiscount className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "discounts",
      active: "discounts"
    },
    {
      icon: <TbMessageReport className="shrink-0 size-4 w-[18px] h-[18px]" />,
      title: "reports",
      active: "reports"
    },
  ];

  const drawerLink = (link) => {
    navigate(`/admin/dashboard/${link}`);
    handleClose();
  };

  return (
    <>
      <header className="sticky top-0 inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap w-full bg-white border-b text-sm py-0.5 lg:ps-[260px] z-[5]">
        <nav className="px-4 sm:px-6 flex basis-full items-center w-full mx-auto">
          <div className="me-5 lg:me-0 lg:hidden">
            <button onClick={handleOpen} className="hidden max-lg:block">
              <FaBars className="w-5 h-5 hover:text-blue-500" />
            </button>
          </div>

          <div className="w-full flex items-center justify-end ms-auto gap-x-1">
            <div className="flex flex-row items-center justify-end gap-1">
              {/* <!-- Dropdown --> */}
              <div className="hs-dropdown [--placement:bottom-right] relative inline-flex">
                <Dropdown label="sign" dismissOnClick={true} renderTrigger={() => (
                  <button>
                    {/* <HiMiniUser className="shrink-0 size-9 rounded-full bg-gray-800 text-white" /> */}
                    <HiUserCircle className="shrink-0 size-11 rounded-full text-gray-800" />
                  </button>
                )}>
                  <Dropdown.Item href='/admin/dashboard'>{t("dashboard")}</Dropdown.Item>
                  <Dropdown.Item><Logout compo="text" text="Logout" /></Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* <!--Sidebar --> */}
      <div className="hs-overlay  hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform w-[260px] h-full hidden fixed inset-y-0 start-0 bg-white border-e border-gray-200 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 z-10">
        <div className="relative flex flex-col h-full max-h-full">
          <a href="/" className="flex flex-wrap items-center text-2xl text-black font-bold px-5 pt-5">Shared<span className="text-blue-600">Store</span></a>
          <div className="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300">
            <nav className="hs-accordion-group p-3 w-full flex flex-col flex-wrap">
              <ul className="flex flex-col space-y-2">
                {dashSections.map((item, index) => (
                  <li key={index}>
                    <Link to={`/admin/dashboard/${item.active}`} onClick={() => setActiveLink(index)} className={`${index === activeLink ? "bg-gray-100" : "bg-white"} flex items-center leading-none gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100`}>
                      {item.icon} {t(item.title)}
                    </Link>
                  </li>
                ))}
                <ChangeLang typeOfCompo="sidebar" />
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="w-full lg:ps-64">
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6 min-h-[95vh] bg-gray-100">
          <Routes>
            <Route path="" element={<DashboardMain />} />
            <Route path="products" element={<ProductList />} />
            <Route path="categories" element={<CategoriesList />} />
            <Route path="licenses" element={<LicensesList />} />
            <Route path="add_product" element={<Cproduct />} />
            <Route path="edit_product/:slug" element={<Uproduct />} />
            <Route path="orders" element={<OrdersList />} />
            <Route path="users" element={<UsersList />} />
            <Route path="transactions" element={<TransactionsList />} />
            <Route path="coupons" element={<CouponsList />} />
            <Route path="discounts" element={<DiscountsList />} />
            <Route path="reports" element={<ReportsList />} />
            <Route path="report/:id" element={<ReportView />} />
          </Routes>
        </div>
      </div>

      {/* Drawer : */}
      <Drawer open={isOpen} onClose={handleClose}>
        <Drawer.Items>
          <div className="relative flex flex-col h-full max-h-full">
            <h1 className="flex flex-wrap items-center text-2xl text-black font-bold px-5 pt-5">Shared<span className="text-blue-600">Store</span></h1>
            <div className="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300">
              <nav className="hs-accordion-group p-3 w-full flex flex-col flex-wrap">
                <ul className="flex flex-col space-y-2">
                  {dashSections.map((item, index) => (
                    <li key={index}>
                      <button onClick={() => drawerLink(item.active)} className="flex items-center leading-none gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                        {item.icon} {t(item.title)}
                      </button>
                    </li>
                  ))}
                  <ChangeLang typeOfCompo="sidebar" />
                </ul>
              </nav>
            </div>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  )
}
