import React from 'react'

export default function DollarIcon() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="50px" height="50px" viewBox="0 0 286.000000 286.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,286.000000) scale(0.100000,-0.100000)"
        className='fill-black group-hover:fill-white' stroke="none">
        <path d="M1209 2674 c-459 -83 -838 -413 -984 -857 -210 -643 120 -1338 750
-1581 154 -59 277 -81 450 -81 172 1 258 14 401 60 199 65 352 160 504 314 58
58 126 138 152 176 102 155 174 332 204 505 22 127 14 378 -15 495 -78 313
-242 561 -496 748 -150 110 -370 201 -550 227 -98 14 -326 11 -416 -6z m396
-125 c164 -21 362 -103 505 -210 294 -218 460 -548 463 -914 2 -447 -255 -848
-667 -1040 -430 -200 -943 -109 -1279 227 -232 233 -348 532 -334 862 7 169
38 293 111 441 154 313 420 529 754 615 141 35 275 41 447 19z"/>
        <path d="M1370 2176 c0 -43 -1 -44 -37 -51 -80 -15 -142 -46 -196 -100 -142
-139 -138 -389 9 -530 44 -42 142 -97 197 -110 l27 -7 0 -189 c0 -124 -4 -189
-10 -189 -23 0 -100 94 -117 142 l-18 50 -65 -6 c-131 -13 -160 -19 -160 -31
0 -7 9 -42 20 -78 27 -87 87 -174 149 -216 53 -36 140 -70 179 -71 21 0 22 -4
22 -85 l0 -85 65 0 65 0 0 83 0 84 63 17 c168 47 271 158 298 324 22 131 -21
268 -106 337 -49 40 -121 77 -197 101 l-58 18 0 178 c0 167 1 178 18 169 28
-15 60 -54 77 -94 18 -42 10 -41 138 -25 l98 12 -6 31 c-26 130 -134 234 -276
267 l-49 11 0 44 0 43 -65 0 -65 0 0 -44z m0 -391 c0 -109 -3 -155 -11 -155
-7 0 -31 19 -54 43 -74 73 -71 162 5 231 22 20 45 36 50 36 6 0 10 -59 10
-155z m228 -498 c36 -33 52 -71 52 -122 0 -80 -39 -139 -113 -170 l-37 -15 0
180 0 180 37 -15 c21 -9 48 -26 61 -38z"/>
      </g>
    </svg>
  )
}
