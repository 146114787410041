import ProductCard from '../../elements/ProductCard';

export default function Products({allData}) {
  return (
    <div className='col-span-4 max-md:col-span-5 min-h-screen'>
      <div className="mb-4 mt-3 grid sm:grid-cols-2 md:mb-8 lg:grid-cols-3 xl:grid-cols-3 gap-4">
        {
          allData.map((item, index) => (
            <ProductCard key={index} data={item} />
          ))
        }
      </div>
    </div>
  )
}
