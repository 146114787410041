import React from 'react'
import TopBar from '../additions/TopBar';
import Header from '../additions/Header';
import Footer from '../additions/Footer';
import OrderConfiramtion from './OrderConfiramtion';

export default function Order() {
  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className='bg-gray-100'>
        <OrderConfiramtion />
      </div>
      <Footer />
    </section>
  )
}
