import axios from "axios";
import i18next from "i18next";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";

export const getAllCategories = async () => {
  const localhost = process.env.REACT_APP_LOCALHOST;
  try {
    const response = await axios.get(`${localhost}/allcategories`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category info:', error);
    return null;
  }
};

export const GetCategory = ({ category_id }) => {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!category_id) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${localhost}/allcategories/${category_id}`);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error!");
        console.error(err);
      };
    };

    fetchData();
  }, [category_id, localhost]);

  if (loading) {
    return <div><Spinner className="fill-black text-gray-300 w-5 h-5" /></div>;
  }

  if (error) {
    return <div className="text-red-600">Error</div>;
  }

  return data.title[i18next.language];
};
