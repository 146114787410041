import { Dropdown } from 'flowbite-react'
import ProductCard from '../../elements/ProductCard';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function ProductsList() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [sortType, setSortType] = useState('bestseller');
  const { refreshKey } = useRefresh();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${localhost}/productlist?sort=${sortType}`);
        setProducts(response.data.slice(0, 8));
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchProducts();
  }, [refreshKey, sortType]);

  return (
    <section className="bg-gray-100 py-8 max-md:pt-2 antialiased md:py-12">
      <div className="container mx-auto max-w-screen-xl px-4 2xl:px-0">
        {/* <!-- Heading & Filters --> */}
        <div className="mb-4 flex justify-between items-center space-y-4 sm:space-y-0">
          <h2 className="text-xl w-max font-medium text-black sm:text-2xl">{t("best-sellers")}</h2>
          <div className="flex items-center w-max space-x-4">

            <Dropdown label="" dismissOnClick={true} renderTrigger={() => (
              <button className="flex w-full items-center justify-center rounded-lg myborder bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 sm:w-auto shadow-sm">
                <svg className="-ms-0.5 me-2 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M7 4l3 3M7 4 4 7m9-3h6l-6 6h6m-6.5 10 3.5-7 3.5 7M14 18h4" />
                </svg>
                {t("sort")}
                <svg className="-me-0.5 ms-2 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 9-7 7-7-7" />
                </svg>
              </button>
            )}>
              <Dropdown.Item onClick={() => setSortType('newest')}>{t("newest")}</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortType('bestseller')}>{t("best-sellers")}</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortType('increasingprice')}>{t("inc-price")}</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortType('decreasingprice')}>{t("dec-price")}</Dropdown.Item>
            </Dropdown>

          </div>
        </div>

        {/* Products list : */}
        {
          loading ?
            (<div className='flex justify-center items-center min-h-[60vh] w-full'>
              <LoadingShopPart />
            </div>)
            : (<div className="mb-4 grid sm:grid-cols-2 md:mb-8 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {
                products.map((item, index) => (
                  <ProductCard key={index} data={item} />
                ))
              }
            </div>)
        }
      </div>

    </section>
  )
}
