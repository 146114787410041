import React from 'react'

export default function EmailIcon() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="50px" height="50px" viewBox="0 0 286.000000 286.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,286.000000) scale(0.100000,-0.100000)"
        className='fill-black group-hover:fill-white' stroke="none">
        <path d="M2465 2705 c-110 -41 -261 -97 -335 -124 -185 -68 -621 -229 -815
-301 -228 -84 -505 -186 -760 -279 -361 -133 -436 -163 -451 -185 -22 -32 -18
-93 8 -119 13 -12 117 -73 233 -134 116 -61 342 -183 504 -270 l293 -159 274
-509 c150 -281 283 -518 295 -528 34 -28 93 -22 121 11 36 43 962 2566 954
2598 -11 41 -50 74 -88 74 -18 -1 -123 -34 -233 -75z m-165 -273 c0 -4 -249
-256 -552 -559 l-553 -552 -380 204 c-209 112 -384 206 -390 208 -5 2 -7 7 -4
11 6 6 1856 694 1872 695 4 1 7 -2 7 -7z m-202 -1091 c-189 -512 -345 -931
-348 -931 -4 0 -406 741 -418 772 -2 4 245 255 550 558 304 302 554 545 556
541 2 -5 -151 -428 -340 -940z"/>
      </g>
    </svg>
  )
}
