import React from 'react';
import Footer from '../../ecrans/additions/Footer';
import TopBar from '../../ecrans/additions/TopBar';
import Header from '../../ecrans/additions/Header';
import AccountDetails from '../../ecrans/dashboard/AccountDetails';

export default function AccountSettings() {
  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className='bg-gray-100'>
        <div className='container py-20'>
          <AccountDetails admin={true} />
        </div>
      </div>
      <Footer />
    </section>
  )
}
