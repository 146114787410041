import { useTranslation } from 'react-i18next';
import { FormatDateTableUsers } from '../../../elements/FormatDate';

export default function PaypalTable({ data }) {
  const { t } = useTranslation();
  const filteredPaypal = data.filter((item) => item.paymentmethod === 'paypal');

  return (
    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
      <thead className="text-xs text-gray-700 bg-gray-50">
        <tr>
          <th className="px-6 py-3">Transaction ID</th>
          <th className="px-6 py-3">{t("fullname")}</th>
          <th className="px-6 py-3">{t("email")}</th>
          <th className="px-6 py-3">{t("date&time")}</th>
          <th className="px-6 py-3">{t("amount")}</th>
          <th className="px-6 py-3">{t("payment-method")}</th>
          <th className="px-6 py-3">Status</th>
          <th className="px-6 py-3">{t("view")}</th>
        </tr>
      </thead>
      <tbody>
        {
          filteredPaypal.map((item, index) => (
            <tr key={index} className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-semibold text-gray-900 whitespace-nowrap">
                {item.uniquePayment}
              </th>
              <td className="px-6 py-4">{item.fullname}</td>
              <td className="px-6 py-4">{item.email}</td>
              <td className="px-6 py-4">
                <FormatDateTableUsers dateString={item.created_at} />
              </td>
              <td className="px-6 py-4">${item.amount}</td>
              <td className="px-6 py-4 capitalize">{item.paymentmethod}</td>
              <td className="px-6 py-4 text-sm font-medium">
                {item.paymentstatus.toLowerCase() === "completed" ?
                  <p className='text-green-600 bg-green-200 rounded-md px-2 text-center w-max'>{t("completed")}</p>
                  : item.paymentstatus.toLowerCase() === "cancelled" ?
                    <p className='text-red-600 bg-red-200 rounded-md px-2 text-center w-max'>{t("cancelled")}</p>
                    : item.paymentstatus.toLowerCase() === "pending" ? <p className='text-yellow-600 bg-yellow-200 rounded-md px-2 text-center w-max'>{t("pending")}</p> : null}
              </td>

              <td className="px-6 py-4">
                <button className='myborder px-2 py-1 text-black text-sm rounded-md hover:bg-gray-100'>{t("view")}</button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}
