import React, { useEffect, useState } from 'react';
import { Dropdown } from 'flowbite-react';
import FilterBar from './FilterBar';
import Products from './Products';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { getAllCategories } from '../../general additions/getCategories';
import i18next from 'i18next';
import { TbSortAscending } from "react-icons/tb";
import { LuChevronDown } from "react-icons/lu";

export default function CategoriesBar() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All-products');
  const [sortType, setSortType] = useState('newest');

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true)
      try {
        const categoryData = await getAllCategories();
        if (categoryData) {
          setCategories(categoryData);
        }

        const response = await axios.get(`${localhost}/productlist?sort=${sortType}`);
        setProducts(response.data);
        setLoading(false);
        applyCategoryFilter(response.data, selectedCategory);
      } catch (err) {
        console.error(err);
      }
    };
    fetchProducts();
  }, [sortType, selectedCategory]);

  const applyCategoryFilter = (productsList, category) => {
    if (category === 'All-products') {
      setFilteredProducts(productsList);
    } else {
      const filtered = productsList.filter(product => parseInt(category) === product.category_id);
      setFilteredProducts(filtered);
    }
  };

  const filterByCategory = (category) => {
    setSelectedCategory(category);
    applyCategoryFilter(products, category);
  };

  return (
    <div className='container pb-6'>
      <div className="flex justify-between items-center border-b py-4 space-y-4 sm:space-y-0">
        <h2 className="text-xl font-medium text-black sm:text-2xl">{t("our-products")}</h2>
        <div className="flex items-center space-x-4">
          <Dropdown label="" dismissOnClick={true} renderTrigger={() => (
            <button className="flex w-full items-center justify-center rounded-lg myborder shadow-sm bg-white px-3 py-2 text-sm font-medium text-black hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 sm:w-auto">
              <TbSortAscending className='-ms-0.5 me-2 h-4 w-4' />
              {t("sort")}
              <LuChevronDown className='-me-0.5 ms-2 h-4 w-4' />
            </button>
          )}>
            <Dropdown.Item onClick={() => setSortType('newest')}>{t("newest")}</Dropdown.Item>
            <Dropdown.Item onClick={() => setSortType('bestseller')}>{t("best-sellers")}</Dropdown.Item>
            <Dropdown.Item onClick={() => setSortType('increasingprice')}>{t("inc-price")}</Dropdown.Item>
            <Dropdown.Item onClick={() => setSortType('decreasingprice')}>{t("dec-price")}</Dropdown.Item>
          </Dropdown>

          <FilterBar onCategorySelect={filterByCategory} />
        </div>
      </div>

      <div className='grid grid-cols-5 grid-flow-col gap-3'>
        <div className='flex flex-col col-span-1 py-2 gap-2 border-r max-md:hidden'>
          <button onClick={() => filterByCategory("All-products")}
            className={`flex flex-wrap items-center gap-2 py-2 hover:text-blue-700 focus:text-blue-700 text-sm font-semibold ${selectedCategory === "All-products" ? "text-blue-600" : "text-black"}`}>
            {t("All-products")}
          </button>
          {categories.map((item, index) => (
            <button key={index} onClick={() => filterByCategory(item.categoryID)}
              className={`flex flex-wrap items-center gap-2 py-2 hover:text-blue-700 focus:text-blue-700 text-sm font-semibold ${selectedCategory === item.categoryID ? "text-blue-600" : "text-black"}`}>
              {item.title[i18next.language]}
            </button>
          ))}
        </div>

        {
          loading ?
            (<div className='flex justify-center items-center col-span-4 min-h-[65vh] max-md:col-span-5'>
              <LoadingShopPart />
            </div>)
            : <Products allData={filteredProducts} />
        }
      </div>
    </div>
  );
}
