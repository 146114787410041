import React, { useEffect, useState } from 'react';
import Header from '../additions/Header';
import Footer from '../additions/Footer';
import TopBar from '../additions/TopBar';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from '../../auth/getUserInfo';
import axios from 'axios';
import OwnToast from '../../general additions/OwnToast';

export default function ContactUs() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        setEmail(userInfo.email);
        setName(userInfo.fname && userInfo.lname ? `${userInfo.fname} ${userInfo.lname}` : '');
      }
    };
    fetchUserInfo();
  }, []);

  const handleContact = (e) => {
    e.preventDefault();
    const mail = { name, email, subject, message };
    axios.post(`${localhost}/user/report`, mail)
      .then((res) => {
        OwnToast('success', t("sent-msg-success"));
        setTimeout(() => { window.location.reload() }, 2000);
        console.log(res.data);
      }).catch((err) => {
        OwnToast('error', t("sent-msg-error"));
      });
  }

  return (
    <section className="">
      <TopBar />
      <Header />
      <div className='bg-gray-100 mb-8'>
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-3 text-2xl tracking-tight font-extrabold text-center text-black">{t("footer-list1.item2")}</h2>
          <p className="mb-12 font-light text-center text-gray-500 text-sm max-w-xl mx-auto">
            {t("contact-text-page-1")} {" "}
            {t("contact-text-page-2")}
          </p>
          <form onSubmit={handleContact}>
            <div className='grid grid-cols-2 gap-2 mb-3'>
              <div className='col-span-1'>
                <label htmlFor="name" className="block mb-1 text-sm font-semibold text-black">{t("fullname")}</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="shadow-sm bg-white myborder text-black text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder={t("fullname")} required />
              </div>
              <div className='col-span-1'>
                <label htmlFor="email" className="block mb-1 text-sm font-semibold text-black">{t("email")}</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="shadow-sm bg-white myborder text-black text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder={t("email")} required />
              </div>
            </div>
            <div className='mb-3'>
              <label htmlFor="subject" className="block mb-1 text-sm font-semibold text-black">{t("subject")}</label>
              <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="block p-3 w-full text-sm text-black bg-white rounded-lg myborder shadow-sm focus:ring-blue-600 focus:border-blue-600" placeholder="Let us know how we can help you" required />
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-1 text-sm font-semibold text-black">{t("message")}</label>
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} rows="6" className="block p-2.5 w-full text-sm text-black bg-white rounded-lg shadow-sm myborder focus:ring-blue-600 focus:border-blue-600" placeholder="Leave a comment..."></textarea>
            </div>
            <div className='w-full flex justify-end mt-4'>
              <button type="submit" className=" py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-gray-900 sm:w-fit hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300">Send message</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </section>
  )
}
