import React from 'react'
import Header from '../additions/Header'
import Footer from '../additions/Footer'
import { aboutImg1 } from '../../assets/images'
import TopBar from '../additions/TopBar'
import { useTranslation } from 'react-i18next'

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />
      <Header />
      <div className='bg-gray-100'>
        <div className='container'>
          <div className="py-6 relative z-0">
            <h1 className="max-w-2xl mx-auto text-center font-manrope font-semibold text-2xl text-black mb-6">
              {t("footer-list1.item1")}
            </h1>
            <div className="mb-2 rounded-full">
              <h2 className="text-lg font-manrope font-semibold text-blue-700 text-left">
                {t("about-page-title-1")} {t("about-page-title-2")}
              </h2>
            </div>
            <div className="">
              <p className="max-w-4xl text-sm text-gray-800 mb-8 text-left">
                {t("about-page-text-1")} <br /> 
                {t("about-page-text-2")} <br /> 
                {t("about-page-text-3")}
              </p>
            </div>
          </div>

          <div className="relative xl:mr-0 lg:mr-5 mr-0">
            <div className="w-full max-w-7xl mx-auto">
              <div className="w-full justify-start items-center gap-5 grid lg:grid-cols-2 grid-cols-1">
                <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                  <div className="w-full flex-col justify-center items-start gap-8 flex">

                    <div className="w-full grid grid-cols-2 items-start gap-4">
                      <div className="col-span-1 h-full p-3.5 rounded-lg myborder bg-white shadow-sm flex-col gap-2.5 inline-flex">
                        <h4 className="text-black text-2xl font-semibold font-manrope leading-9">+23K</h4>
                        <p className="text-gray-600 text-xs">
                          <span className='font-semibold text-sm text-gray-800'>Customers served</span> People love us
                        </p>
                      </div>
                      <div className="col-span-1 h-full p-3.5 rounded-lg myborder bg-white shadow-sm flex-col gap-2.5 inline-flex">
                        <h4 className="text-black text-2xl font-semibold font-manrope leading-9">+9.6/10</h4>
                        <p className="text-gray-600 text-xs">
                          <span className='font-semibold text-sm text-gray-800'>Our trustscore</span> People love us
                        </p>
                      </div>
                      <div className="col-span-1 h-full p-3.5 rounded-lg myborder bg-white shadow-sm flex-col gap-2.5 inline-flex">
                        <h4 className="text-black text-2xl font-semibold font-manrope leading-9">+1000</h4>
                        <p className="text-gray-600 text-xs">
                          <span className='font-semibold text-sm text-gray-800'>Reviews worldwide</span> +96% positive reviews
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="w-full lg:justify-start justify-center items-start flex">
                  <img className="w-full h-auto object-cover"
                    src={aboutImg1} alt="about us" />
                </div>
              </div>
            </div>
          </div>

          <div className="py-28">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h2 className="font-manrope text-2xl text-center text-black font-semibold mb-6">Our results in numbers</h2>
              <div className="flex flex-col gap-5 xl:gap-8 lg:flex-row lg:justify-between">
                <div className="w-full max-lg:max-w-2xl mx-auto lg:mx-0 lg:w-1/3 bg-white p-6 myborder rounded-lg shadow-sm">
                  <div className="flex gap-5">
                    <div className="font-manrope text-xl font-semibold text-black">240%</div>
                    <div className="flex-1">
                      <h4 className="text-lg text-gray-800 font-semibold mb-1">Company growth</h4>
                      <p className="text-xs text-gray-600">Company's remarkable growth journey as we continually innovate and drive towards new heights of success.</p>
                    </div>
                  </div>
                </div>
                <div className="w-full max-lg:max-w-2xl mx-auto lg:mx-0 lg:w-1/3 bg-white p-6 myborder rounded-lg shadow-sm">
                  <div className="flex gap-5">
                    <div className="font-manrope text-xl font-semibold text-black">175+</div>
                    <div className="flex-1">
                      <h4 className="text-lg text-gray-800 font-semibold mb-1">Company growth</h4>
                      <p className="text-xs text-gray-600">
                        Our very talented team members are the powerhouse of pagedone and pillars of our success. </p>
                    </div>
                  </div>
                </div>
                <div className="w-full max-lg:max-w-2xl mx-auto lg:mx-0 lg:w-1/3 bg-white p-6 myborder rounded-lg shadow-sm">
                  <div className="flex gap-5">
                    <div className="font-manrope text-xl font-semibold text-black">625+</div>
                    <div className="flex-1">
                      <h4 className="text-lg text-gray-800 font-semibold mb-1">Projects Completed</h4>
                      <p className="text-xs text-gray-600">We have accomplished more than 625 projects worldwide and we are still counting many more.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </section >
  )
}
