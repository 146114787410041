import React from 'react'

export function CheckedIcon() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="50px" height="50px" viewBox="0 0 286.000000 286.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,286.000000) scale(0.100000,-0.100000)"
        className='fill-black group-hover:fill-white' stroke="none">
        <path d="M2382 2671 c-114 -33 -145 -62 -374 -348 -189 -235 -470 -636 -684
-974 -104 -164 -132 -197 -154 -184 -16 10 -55 93 -89 187 -37 106 -53 132
-91 148 -79 33 -274 -77 -297 -168 -8 -34 19 -147 79 -327 58 -175 106 -280
142 -314 24 -23 33 -24 148 -25 101 -1 129 2 156 17 24 13 69 73 165 220 344
523 735 1055 1121 1527 70 85 137 173 148 194 19 38 20 40 2 53 -27 20 -194
16 -272 -6z"/>
        <path d="M1195 2534 c-493 -92 -880 -475 -971 -961 -25 -137 -16 -395 20 -528
65 -241 212 -469 401 -621 421 -340 984 -366 1434 -67 309 206 510 563 528
938 13 275 -65 539 -228 768 l-27 37 -57 -64 -56 -64 35 -49 c97 -135 153
-310 163 -508 25 -499 -290 -933 -772 -1062 -89 -23 -118 -26 -260 -26 -143
-1 -170 2 -260 27 -175 48 -333 138 -461 263 -335 328 -406 852 -170 1253 94
159 249 308 408 390 163 85 261 110 458 117 182 6 302 -13 421 -68 42 -20 73
-29 77 -23 4 5 27 36 51 68 51 66 54 60 -58 107 -147 61 -249 81 -431 85 -116
2 -189 -1 -245 -12z"/>
      </g>
    </svg>

  )
}
