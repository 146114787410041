import React, { useEffect, useState } from 'react';
import { Spinner } from "flowbite-react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router';
import ReplyMail from '../ReportBtns/ReplyMail';

export default function ReportView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState([]);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const res = await axios.get(`${localhost}/admin/report/${id}`, { headers: { Authorization: `Bearer ${token}` } });
        setReport(res.data);
        setLoading(false);
      } catch (err) { console.error(err) };
    }
    fetchReport();
  }, []);


  return (
    <section className='w-full'>
      <div className='max-w-4xl mx-auto pt-16 max-md:pt-6 pb-4'>
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-semibold text-gray-900">{t("hi-admin")}</h3>
          <p className="max-w-2xl text-sm text-gray-500">{t("hi-text-admin-1")}<br />{t("hi-text-admin-2")}</p>
        </div>
        {
          loading ? <div className='min-h-[425px] w-full flex justify-center items-center'><Spinner className='w-8 h-8 text-gray-400 fill-blue-600' /> </div>
            : <div className="mt-6 border-t border-gray-300">
              <dl className="divide-y divide-gray-300">
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm text-gray-900 font-semibold">{t("report")} ID</dt>
                  <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{report.uniqueReport}</dd>
                </div>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm text-gray-900 font-semibold">{t("fullname")}</dt>
                  <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{report.name}</dd>
                </div>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm text-gray-900 font-semibold">{t("email")}</dt>
                  <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{report.email}</dd>
                </div>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm text-gray-900 font-semibold">{t("subject")}</dt>
                  <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{report.subject}</dd>
                </div>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm text-gray-900 font-semibold">Message</dt>
                  <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{report.message}</dd>
                </div>
              </dl>
            </div>
        }
        <div className='pt-4 flex flex-wrap justify-end gap-2 items-center w-full'>
          <ReplyMail idProp={report.id} emailProp={report.email} />
        </div>
      </div>
    </section>
  )
}
