import { HiCheckCircle, HiPlus, HiMinus } from 'react-icons/hi2';
import { useEffect, useState } from 'react';
import Reviews from './Reviews';
import axios from 'axios';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import RichTextEditor from '@mantine/rte';
import RatingStars from '../../elements/RatingStars';
import AddCart from '../../elements/AddCart';
import FavoriteBtn from '../../elements/FavoriteBtn';
import { blankimage } from '../../assets/images';
import { useRefresh } from '../../general additions/RefreshProvider';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { GetCategory } from '../../general additions/getCategories';
import SystemRequirements from './SystemRequirements';
import { GetLicense } from '../../general additions/getLicenses';

export default function Details() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const localhostImage = process.env.REACT_APP_LOCALHOST_IMG;
  const { refreshKey } = useRefresh();
  const token = localStorage.getItem("token");

  const [quantity, setQuantity] = useState(1);
  const [activeSection, setActiveSection] = useState("details");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [discount, setDiscount] = useState([]);
  const [exist, setExist] = useState(false);
  const [discountText, setDiscountText] = useState("");

  // Remove findDOMNode error :
  const originalError = console.error;
  console.error = (...args) => {
    if (/findDOMNode is deprecated/.test(args[0])) {
      return;
    }
    originalError(...args);
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await axios.get(`${localhost}/admin/getdiscountsbyid/${1}`)
        setExist(res.data.discountID ? true : false);
        setDiscount(res.data);
      }
      fetchData();
    } catch (err) {
      // 
    }
  }, [refreshKey]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${localhost}/productreview/${slug}`);
        const discountRes = await axios.get(`${localhost}/admin/getdiscountsbyid/${res.data[0].productID}`);

        setExist(discountRes.data.discountID ? true : false);
        setDiscount(discountRes.data);
        setDiscountText(discountRes.data.percentage ? parseInt(discountRes.data.percentage) + '%' : parseFloat(discountRes.data.amount) + '$');

        const productData = res.data[0];
        // Parse JSON strings
        productData.name = JSON.parse(productData.name);
        productData.details = JSON.parse(productData.details);
        productData.technical_specs = JSON.parse(productData.technical_specs);
        setProduct(productData);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [localhost, slug, refreshKey]);

  if (loading) return <div className='flex justify-center items-center min-h-[70vh]'><LoadingShopPart /></div>;

  return (
    <div className="py-12 antialiased">
      <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
        <div className="lg:grid lg:grid-cols-2 lg:gap-6 xl:gap-8 items-center">

          <div className="relative max-w-md max-md:mb-5 mx-auto">
            <a href={"/product/" + product.slug}>
              <img className="w-full" src={product.image ? localhostImage + product.image : blankimage} alt={product.name[i18n.language]} />
              {/* <span className={`absolute top-0 right-0 ${product.stock > 0 ? "bg-green-700" : "bg-red-700"} text-white text-sm px-3 py-1.5 rounded-bl-lg`}>
                {product.stock > 0 ? t("available") : t("unavailable")}
              </span> */}
              {exist ? <span className={`absolute top-2 right-2 bg-blue-600 text-white text-xs px-2 py-1 rounded-full`}>
                -{discountText}
              </span> : null}
            </a>
          </div>

          <div>
            <div className='flex flex-wrap items-center justify-center gap-2 w-52 text-black text-sm font-semibold ring-1 ring-gray-900 rounded-full px-2.5 py-1.5 mb-5'>
              <GetCategory category_id={product.category_id} />
            </div>

            <h1 className="text-xl font-semibold text-black sm:text-2xl mb-5">{product.name[i18n.language] || product.name.en}</h1>

            <div className="mb-5 sm:items-center sm:flex">
              <div className="flex items-center gap-2 mt-2 sm:mt-0">
                <div className="flex items-center gap-1">
                  <RatingStars size={4} rating={product.ratings} />
                </div>
                <p className="text-sm font-medium leading-none text-gray-500">({product.ratings.toFixed(2)})</p>
                <p className="text-sm font-medium leading-none text-black underline hover:no-underline">
                  {product.countreview} {t("reviews")}
                </p>
              </div>
            </div>

            {exist ? <span className="me-2 rounded text-xs font-medium text-red-700">{t("up-to-discount", { value: discountText })}</span> : <div></div>}
            <div className='flex flex-wrap mb-5 items-end gap-1 mt-1'>
              <p className="text-2xl font-extrabold text-black sm:text-3xl leading-none">${exist ? discount.newPrice.toFixed(2) : product.price.toFixed(2)}</p>
              {exist ? <span className="text-red-700 line-through text-sm font-semibold mb-1">${product.price.toFixed(2)}</span> : null}
            </div>

            <div className='flex flex-wrap items-center gap-2 mb-3 text-black'>
              <span className='font-semibold'>{t("license")}</span>
              <GetLicense license_id={product.license_id} />
            </div>

            <div>
              <ul className="max-w-md space-y-1 text-gray-700 text-md mb-5 list-inside">
                {
                  ["prolist-1", "prolist-2"].map((item, index) => (
                    <li key={index} className="flex items-center">
                      <HiCheckCircle className="w-4 h-4 me-2 text-green-500 flex-shrink-0" />
                      {t(item)}
                    </li>
                  ))
                }
              </ul>
            </div>

            <div className="flex items-center">
              <button onClick={() => setQuantity(Math.max(1, quantity - 1))}
                className="flex justify-center items-center disabled:text-gray-300 bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7" disabled={quantity <= 1}>
                <HiMinus className="w-5 h-5" />
              </button>

              {
                product.stock < 1 ? <input type="text" value={0} className="text-center w-14 border-0 ring-0 focus:ring-0" />
                  : <input type="text" value={quantity} onChange={(e) => setQuantity(Math.min(Math.max(1, parseInt(e.target.value) || 1), product.stock))}
                    className="text-center w-14 bg-transparent border-0 ring-0 focus:ring-0" />
              }

              <button onClick={() => setQuantity(prevQuantit => Math.min(product.stock, prevQuantit + 1))}
                className="flex justify-center items-center disabled:text-gray-300 bg-gray-200 hover:bg-gray-300 rounded-md h-7 w-7" disabled={quantity >= product.stock}>
                <HiPlus className="w-5 h-5" />
              </button>
            </div>

            <div className="sm:gap-4 sm:items-center sm:flex mt-16">
              {
                token ? <FavoriteBtn prodID={product.productID} /> : null
              }

              <AddCart prodID={product.productID} quantity={quantity} typeBtn="noModal" stockProd={product.stock} />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-10'>
        <div className='flex flex-wrap justify-center my-6 border-t border-blue-700 gap-2 items-center'>
          <button className={`px-3 py-2 border-b-4 ${activeSection === "details" ? "borderBar" : "borderBarWhite"}`} onClick={() => setActiveSection("details")}>Description</button>
          <button className={`px-3 py-2 border-b-4 ${activeSection === "reviews" ? "borderBar" : "borderBarWhite"}`} onClick={() => setActiveSection("reviews")}>{t("reviews")} ({product.countreview})</button>
          {
            product.technical_specs ? <button className={`px-3 py-2 border-b-4 ${activeSection === "system-req" ? "borderBar" : "borderBarWhite"}`} onClick={() => setActiveSection("system-req")}>{t("system-req")}</button> : null
          }
        </div>
        {
          activeSection === "details" ? <RichTextEditor style={{ background: "transparent", border: "transparent" }} value={product.details[i18n.language] || product.details.en} readOnly />
            : activeSection === "reviews" ? <Reviews productId={product.productID} ratingProd={product.ratings} countRev={product.countreview} />
              : <SystemRequirements data={product.technical_specs} />
        }
      </div>
    </div>
  );
}
