import { HiPhone, HiEnvelope } from 'react-icons/hi2';
import ChangeLang from '../../general additions/ChangeLang';

export default function TopBar() {
  return (
    <>
      <div className="flex items-center justify-center gap-x-6 bg-gray-900 text-white py-2 overflow-hidden max-md:hidden">
        <div className='container flex justify-between items-center'>
          <div className="flex flex-wrap gap-5 text-sm leading-6">
            <div className='flex flex-wrap items-center gap-1'>
              <HiPhone className='h-5 w-auto text-white' /> +212 611-223344
            </div>
            <div className='flex flex-wrap items-center gap-1'>
              <HiEnvelope className='h-5 w-auto text-white' /> support@sharedstore.ma
            </div>
          </div>

          <ChangeLang typeOfCompo="dropdown" />
        </div>
      </div>

      <ChangeLang typeOfCompo="globe" />
    </>
  )
}
