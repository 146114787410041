import { formatDistanceToNow } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import i18next from 'i18next';

export const FormatDateForcomment = ({ dateString }) => {
  const date = new Date(dateString + 'Z');

  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return `${date.toLocaleDateString('en-US', options)}`;
};

export const FormatDateTable = ({ dateString }) => {
  const date = new Date(dateString + 'Z');

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
};

export const FormatDateTableUsers = ({ dateString }) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

export const FormatDateForCoupon = (dateString, language) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat(language, { day: '2-digit', month: 'long', year: 'numeric' }).format(date);
};


export const TimeAgo = ({ dateString }) => {
  const date = new Date(dateString + 'Z');
  const currentLocale = i18next.language === 'fr' ? fr : enUS;

  return formatDistanceToNow(date, { addSuffix: true, locale: currentLocale });
}