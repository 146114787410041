import axios from "axios";
import i18n from 'i18next';
import { Spinner } from 'flowbite-react';
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import Footer from '../additions/Footer';
import TopBar from '../additions/TopBar';
import Header from '../additions/Header';
import PaypalButton from './PaypalButton';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import RemoveOrder from '../../elements/RemoveOrder';
import { getUserInfo } from '../../auth/getUserInfo';
import { countries } from '../../assets/data/countries';
import { FormatDateForcomment } from '../../elements/FormatDate';
import { useRefresh } from '../../general additions/RefreshProvider';
import { americanexpresslogo, maestrologo, mastercardlogo, paypallogo, visalogo } from '../../assets/icons';
import OwnToast from "../../general additions/OwnToast";

export default function CheckoutForm() {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [postcode, setPostcode] = useState('');
  const [cardnumber, setCardnumber] = useState('');
  const [cardexpiration, setCardexpiration] = useState('');
  const [cvv, setCvv] = useState('');
  const [payMethod, setPayMethod] = useState('credit-card');

  // order :
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [userId, setUserId] = useState(null);
  const { refreshKey } = useRefresh();
  const { triggerRefresh } = useRefresh();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        setEmail(userInfo.email);
      } else {
        // console.log(null);
      }
    };
    fetchUserInfo();

    const fetchOrders = async () => {
      const res = await axios.get(localhost + "/getorders", { headers: { 'Authorization': `Bearer ${token}` } });
      setOrders(res.data);
      setUserId(res.data[0].user_id);
      setLoading(false);
    }
    fetchOrders();
  }, [refreshKey]);

  // add payemnt :
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { fullname, email, phone, country, postcode, cardnumber, cardexpiration, cvv, paymentmethod: "vsa", amount: couponData.code ? calculateFinalAmount() : calculateSubtotal() };
    try {
      await axios.post(localhost + '/payments', formData, { headers: { 'Authorization': `Bearer ${token}` } });
      alert('Payment successful!');
      triggerRefresh();
    } catch (err) {
      alert('Payment failed');
    }
  };

  // Subtotal calculation
  const calculateSubtotal = () => {
    const total = orders.reduce((total, item) => total + parseFloat(item.total_amount), 0).toFixed(2);
    return total !== "NaN" ? total : totalPrice;
  };
  const calculateOriginalSubtotal = () => {
    const total = orders.reduce((total, item) => total + parseFloat(item.total_original_amount), 0).toFixed(2);
    return total !== "NaN" ? total : totalPrice;
  };
  // Payments logos :
  const paymentslogo = [
    visalogo,
    mastercardlogo,
    americanexpresslogo,
    maestrologo,
  ];

  // Check coupon :
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState([]);
  const checkCoupon = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.get(`${localhost}/validatecoupon/${couponCode}`, { headers: { Authorization: `Bearer ${token}` } });
      console.log(response.data);

      if (response.data.success) {
        console.log(response.data.coupon);
        setCouponData(response.data.coupon);
      } else {
        let msg = response.data.message;
        if (response.data.start_date) {
          msg = `${t(response.data.message)} ${response.data.start_date}`;
        } else {
          msg = t(response.data.message);
        }
        OwnToast('error', msg);
        console.log(msg);
      }
    } catch (error) {
      console.error('Error validating coupon:', error);
    }
    setLoading(false);
  };

  // New function to calculate subtotal after applying coupon
  const calculateFinalAmount = () => {
    const subtotal = parseFloat(calculateSubtotal());
    if (!couponData.code) {
      return subtotal;
    }

    let discount = 0;
    if (couponData.percentage) {
      discount = (subtotal * couponData.percentage) / 100;
    } else if (couponData.amount) {
      discount = parseFloat(couponData.amount);
    }

    const finalAmount = Math.max(subtotal - discount, 0);
    return finalAmount.toFixed(2);
  };

  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className='bg-gray-100'>
        <div className="container py-8 antialiased md:py-16">
          <div className="mx-auto">
            <h2 className="text-xl font-semibold text-black sm:text-2xl">{t("ppayment")}</h2>

            <div className="mt-6 lg:flex lg:items-start lg:gap-12">
              <div className="w-full lg:max-w-xl">
                <form onSubmit={handleSubmit}>
                  <div className='mb-6 grid grid-cols-2 gap-4'>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-black">{t("email")}<span className='text-red-600 inline-block leading-none text-base ml-1'>*</span></label>
                      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder={t("edit-email")} required />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-black">{t("phone")}</label>
                      <PhoneInput className='bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1.5' placeholder={t("ur-phone")} value={phone} onChange={setPhone} />
                    </div>
                  </div>

                  <div className='mb-6 grid grid-cols-2 gap-4'>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-black">{t("country")}<span className='text-red-600 inline-block leading-none text-base ml-1'>*</span></label>
                      <select value={country} onChange={(e) => setCountry(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required>
                        <option value="" disabled>{t("ur-country")}</option>
                        {
                          countries.map((item, index) => (
                            <option key={index} value={item.name}>{item.name}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block mb-2 text-sm font-medium text-black">{t("postcode")}<span className='text-red-600 inline-block text leading-none-base ml-1'>*</span></label>
                      <input type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder={t("ur-postcode")} />
                    </div>
                  </div>

                  <div className="mb-6 grid grid-cols-2 gap-4 rounded-lg border border-gray-200 bg-white text-black p-4 shadow-sm sm:p-6">
                    {/* radio : */}
                    <div className='col-span-2 flex justify-between gap-1'>
                      <label className='flex items-center gap-2 cursor-pointer text-black font-semibold'>
                        <input type="radio" value="credit-card" checked={payMethod === 'credit-card'}
                          onChange={() => setPayMethod('credit-card')}
                          className='border-gray-400 ring-1 ring-gray-500' name="payment-method" />
                        {t("creditcard")}
                      </label>
                      <div className='flex flex-wrap items-center gap-1'>
                        {
                          paymentslogo.map((item, index) => (
                            <img key={index} src={item} className='w-10 h-auto' alt={"payment method " + (index + 1)} />
                          ))
                        }
                      </div>
                    </div>

                    {payMethod === "credit-card" ?
                      (<>
                        <div className="col-span-2 sm:col-span-1">
                          <label className="mb-2 block text-sm">{t("fullname")}</label>
                          <input type="text" value={fullname} onChange={(e) => setFullname(e.target.value)} className="block w-full rounded-lg myborder bg-gray-50 p-2.5 text-sm text-black focus:border-blue-500 focus:ring-blue-500" placeholder={t("fullname")} required />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <label className="mb-2 block text-sm"> {t("cardnumber")}<span className='text-red-600 inline-block leading-none text-base ml-1'>*</span> </label>
                          <input type="text" value={cardnumber} pattern="^\d{16}$" onChange={(e) => setCardnumber(e.target.value)} className="block w-full rounded-lg myborder bg-gray-50 p-2.5 pe-10 text-sm text-black focus:border-blue-500 focus:ring-blue-500 " placeholder="xxxx-xxxx-xxxx-xxxx" required />
                        </div>

                        <div>
                          <label className="mb-2 block text-sm">{t("cardexpiration")}<span className='text-red-600 inline-block leading-none text-base ml-1'>*</span> </label>
                          <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
                              <svg className="h-4 w-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" clipRule="evenodd" />
                              </svg>
                            </div>
                            <input type="text" value={cardexpiration} pattern="^(0[1-9]|1[0-2])\/?([0-9]{2})$" onChange={(e) => setCardexpiration(e.target.value)} className="block w-full rounded-lg myborder bg-gray-50 p-2.5 ps-9 text-sm text-black focus:border-blue-500 focus:ring-blue-500" placeholder="MM/YY" required />
                          </div>
                        </div>
                        <div>
                          <label className="mb-2 block text-sm">Cvv<span className='text-red-600 inline-block text leading-none-base ml-1'>*</span></label>
                          <input type="number" value={cvv} pattern="^\d{3,4}$" onChange={(e) => setCvv(e.target.value)} className="block w-full rounded-lg myborder bg-gray-50 p-2.5 text-sm text-black focus:border-blue-500 focus:ring-blue-500" placeholder="•••" required />
                        </div>
                        <button type="submit" className="col-span-2 flex items-center justify-center rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4  focus:ring-blue-300">{t("placeorder")}</button>
                      </>) : null}

                    {/* radio : */}
                    <div className='col-span-2 flex justify-between gap-1 mt-3'>
                      <label className='flex items-center gap-2 cursor-pointer text-black font-semibold'>
                        <input type="radio" value="paypal" checked={payMethod === 'paypal'} onChange={() => setPayMethod('paypal')}
                          className='border-gray-400 ring-1 ring-gray-500' name="payment-method" />Paypal</label>

                      <div className='flex flex-wrap items-center gap-1'>
                        <img src={paypallogo} className='w-24 h-auto' alt="paypal" />
                      </div>
                    </div>
                    {payMethod === "paypal" ? (
                      (() => {
                        const orderData = { user_id: userId, email, phone, country, postcode, paymentmethod: "paypal", amount: couponData.code ? calculateFinalAmount() : calculateSubtotal() };
                        return (
                          <div className='col-span-2'>
                            <PaypalButton orderData={orderData} activeBtn={!orders.length > 0} />
                          </div>
                        );
                      })()
                    ) : null}
                  </div>
                </form>

                {/* Coupon : */}
                <div className="bg-white shadow-sm rounded-lg border border-gray-200 p-4 sm:p-6">
                  <form onSubmit={checkCoupon}>
                    <label className="block mb-2 text-sm font-semibold text-black">{t("coupon")}</label>
                    <input type="text" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} placeholder={t("placeholder-code-coupon")}
                      className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-2.5 mb-2" />
                    <div className="flex w-full justify-end">
                      <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-1.5 rounded-md text-sm">Submit</button>
                    </div>
                  </form>
                </div>

              </div>

              <div className="mt-6 grow sm:mt-8 lg:mt-0">
                <p className='text-lg font-semibold text-black mb-3'>{t('product')}s</p>
                <div className="space-y-4">
                  {loading ?
                    (<div className="flex justify-center items-center w-full min-h-[30vh] max-md:min-h-[20vh]">
                      <Spinner className='fill-blue-600 text-gray-200 w-10 h-10' />
                    </div>)
                    : (<ul className='flex flex-col gap-2'>
                      {orders.map((item, index) => {
                        let productName;

                        try {
                          productName = JSON.parse(item.name);
                        } catch (error) {
                          console.error("Error parsing JSON data: ", error);
                          return <p className="text-base text-red-500">{t("error-displaying-infos")}</p>;
                        }

                        return (
                          <li key={index} className="p-4 border rounded-md bg-white shadow-sm">
                            <div className='flex justify-between items-start gap-1'>
                              <p className='text-base text-black mr-1 font-semibold'>{productName[i18n.language]}</p>
                              <RemoveOrder xMark={true} prod_id={item.productID} />
                            </div>

                            <div className='flex justify-between gap-1 items-center'>
                              <p className="text-sm"><FormatDateForcomment dateString={item.updated_at} /></p>
                              <p className='text-sm mt-1 flex flex-wrap font-semibold leading-3'>
                                <span className='text-gray-400 mr-1 font-normal'>{item.total_quantity ? item.total_quantity : item.quantity} x</span> ${item.priceAfterDiscount ? parseFloat(item.priceAfterDiscount).toFixed(2) : parseFloat(item.price).toFixed(2)}
                                {item.priceAfterDiscount ? <span className="ml-1 text-red-700 line-through text-xs font-medium">${parseFloat(item.price).toFixed(2)}</span> : null}
                              </p>
                            </div>

                          </li>
                        );
                      })}
                    </ul>)}

                  <div className='px-1 font-semibold text-black border-t border-gray-200 pt-2'>
                    {couponData.code ?
                      (<>
                        <div className="flex justify-between items-end gap-1 text-sm">
                          <span>{t("coupon")}</span>
                          <span>-{couponData.percentage ? `${couponData.percentage}%` : `$${couponData.amount}`}</span>
                        </div>
                        <div className="flex justify-between items-end gap-1 text-sm mb-1">
                          <span>{t("total-amount")}</span>
                          <span>{calculateSubtotal() === calculateOriginalSubtotal() ? <span>${calculateSubtotal()}</span>
                            : <div className="flex flex-wrap gap-1 ">${calculateSubtotal()}<span className="text-red-700 line-through text-[11px]">${calculateOriginalSubtotal()}</span></div>}</span>
                        </div>
                      </>) : null}

                    <div className="flex justify-between items-end gap-1 text-xl">
                      <span>{t("subtotal")}</span>
                      {couponData.code ? <span>{calculateFinalAmount()}</span>
                        : (<span>
                          {calculateSubtotal() === calculateOriginalSubtotal() ? <span>${calculateSubtotal()}</span>
                            : <div className="flex flex-wrap gap-1 leading-3">${calculateSubtotal()}<span className="text-red-700 line-through text-xs">${calculateOriginalSubtotal()}</span></div>}
                        </span>)}
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-center gap-8">
                  {["paypal", "visa", "mastercard"].map((item, index) => (
                    <img key={index} className="h-8 w-auto" src={`https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/${item}.svg`} alt={item} />
                  ))}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div >

      <Footer />
    </section >
  )
}
