import { HiHeart, HiOutlineHeart } from 'react-icons/hi2';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useRefresh } from '../general additions/RefreshProvider';
import OwnToast from "../general additions/OwnToast";
import { Spinner } from 'flowbite-react';

export default function FavoriteBtn({ prodID, typeBtn }) {
  const { t } = useTranslation();
  const { triggerRefresh } = useRefresh();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const [ref, setRef] = useState(false);
  const [isFavorite, setIsFavorite] = useState("no");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(localhost + "/isfavorite/" + prodID, { headers: { 'Authorization': `Bearer ${token}` } });
        setIsFavorite(res.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [ref]);
  

  const handleFavorite = () => {
    setLoading(true);
    const favData = { 'product_id': prodID };
    axios.post(localhost + "/favorites", favData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        triggerRefresh();
        if (isFavorite === "no") {
          OwnToast('success', t('s-add-fav'));
        } else {
          OwnToast('error', t('s-remove-fav'));
        }
        setLoading(false);
      }).catch();
    setRef(!ref);
  }

  if (typeBtn === "icon") {
    if (loading) {
      return (
        <Spinner className='w-4 h-4 fill-red-600 text-gray-200' />
      )
    } else {
      return (
        <button onClick={handleFavorite} className="rounded-lg hover:bg-gray-100 text-red-700 hover:text-red-800">
          {
            isFavorite === "yes" ?
              <HiHeart className="w-5 h-5" />
              : <HiOutlineHeart className="w-5 h-5" />
          }
        </button>
      )
    }
  } else {
    return (
      <button onClick={handleFavorite} className="flex items-center justify-center px-5 text-[12px] font-medium border border-gray-200 rounded-lg text-red-600 hover:text-red-700">
        {
          isFavorite === "no" ?
            <HiHeart className="w-5 h-5 mr-2" />
            : <HiOutlineHeart className="w-5 h-5 mr-2" />
        }
        {isFavorite === "no" ? t("addfav") : t("nofav")}
      </button>
    )
  }
}
