import React, { useEffect, useState } from 'react';
import { LuArrowDownUp } from "react-icons/lu";
import { useTranslation } from 'react-i18next';
import { useRefresh } from '../../general additions/RefreshProvider';
import axios from 'axios';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { notresultsfound } from '../../assets/images';
import AddCoupon from '../admin elements/AddCoupon';
import DeleteDiscount from '../admin elements/DeleteDiscount';
import CouponCard from '../admin elements/CouponCard';
import { Tooltip } from 'flowbite-react';

export default function CouponsList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const { refreshKey } = useRefresh();
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterOption, setFilterOption] = useState("all-coupons");
  const [copied, setCopied] = useState(false);

  // Fetch offers data
  useEffect(() => {
    axios
      .get(`${localhost}/admin/getoffers/coupon`)
      .then((res) => {
        setOffers(res.data);
        setFilteredOffers(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [refreshKey]);

  // Handle filtering
  const handleFilter = (option) => {
    setFilterOption(option);

    const now = new Date().toISOString().split("T")[0];
    let filtered;

    switch (option) {
      case "active-coupons":
        filtered = offers.filter((offer) => offer.end_date >= now);
        break;
      case "expired-coupons":
        filtered = offers.filter((offer) => offer.end_date < now);
        break;
      default:
        filtered = offers;
        break;
    }

    // After filtering, apply sorting if necessary
    const sorted = [...filtered].sort((a, b) => {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredOffers(sorted);
  };

  // Handle sorting
  const handleSort = (field) => {
    const sorted = [...filteredOffers].sort((a, b) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);

      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredOffers(sorted);
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const isExpired = (endDate) => {
    const currentDate = new Date();
    const offerEndDate = new Date(endDate);
    offerEndDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    return offerEndDate < currentDate;
};

  return (
    <div className="antialiased">
      <div className="flex justify-between gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("special-coupons")}</p>
        <AddCoupon />
      </div>

      <div className="">
        <div className="rounded-lg bg-white mt-3 border shadow-sm p-3 flex justify-end">
          <select value={filterOption} onChange={(e) => handleFilter(e.target.value)}
            className="max-w-36 rounded-lg myborder bg-white px-2 py-1.5 text-sm font-medium text-black hover:border-[#D1D5DB] focus:z-10 focus:outline-none focus:border-gray-200 focus:ring-4 focus:ring-gray-100">
            <option value="all-coupons">{t("all-coupons")}</option>
            <option value="active-coupons">{t("active-coupons")}</option>
            <option value="expired-coupons">{t("expired-coupons")}</option>
          </select>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto rounded-lg bg-white mt-3 border shadow-sm">
          {loading ? (
            <div className="flex justify-center items-center h-[50vh] w-full">
              <LoadingShopPart />
            </div>
          ) : filteredOffers.length > 0 ? (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 bg-gray-50">
                <tr>
                  <th className="px-6 py-3">{t("coupon")} ID</th>
                  <th className="px-6 py-3">Type</th>
                  <th className="px-6 py-3">Code</th>
                  <th className="px-6 py-3">{t("amount")}</th>
                  <th className="px-6 py-3">{t("percentage")}</th>
                  <th className="px-6 py-3">
                    <button onClick={() => handleSort("start_date")} className="flex items-center">
                      {t("startdate")} <LuArrowDownUp className="-me-0.5 ms-2 h-auto w-[14px]" />
                    </button>
                  </th>
                  <th className="px-6 py-3">
                    <button onClick={() => handleSort("end_date")} className="flex items-center">
                      {t("enddate")} <LuArrowDownUp className="-me-0.5 ms-2 h-auto w-[14px]" />
                    </button>
                  </th>
                  <th className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredOffers.map((item, index) => (
                  <tr key={index} className={`border-b ${isExpired(item.end_date) ? 'bg-red-100' : 'bg-green-100'}`}>
                    <th scope="row" className="px-6 py-4 font-semibold text-gray-900 whitespace-nowrap">
                      {item.uniqueDiscount}
                    </th>
                    <td className="px-6 py-4">{item.type}</td>
                    <td className="px-6 py-4">
                      <Tooltip content={copied ? t('copied') : t('copy')}>
                        <button className='hover:text-blue-600 hover:underline' onClick={() => handleCopy(item.code)}>{item.code}</button>
                      </Tooltip>
                    </td>
                    <td className="px-6 py-4">{item.amount ? item.amount : "- -"}</td>
                    <td className="px-6 py-4">
                      {item.percentage ? item.percentage + " %" : "- -"}
                    </td>
                    <td className="px-6 py-4">{item.start_date}</td>
                    <td className="px-6 py-4">{item.end_date}</td>
                    <td className="flex flex-wrap items-center gap-2 px-6 py-4">
                      <CouponCard discountValue={item.percentage ? item.percentage + "%" : parseFloat(item.amount).toFixed(1) + "$"} codeValue={item.code} endDateValue={item.end_date} />
                      <DeleteDiscount discoundID={item.discountID} typeProp="coupon" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center items-center py-10 text-gray-500">
              <div className="col-span-4 text-center text-black flex flex-col gap-6 justify-center items-center min-h-[40vh]">
                <img src={notresultsfound} className="w-48 h-auto" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
