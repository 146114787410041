import { HiArrowLeftStartOnRectangle } from "react-icons/hi2";
import { FaXmark } from "react-icons/fa6";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import OwnToast from "../../general additions/OwnToast";

export default function Logout({ compo, text = "" }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    localStorage.clear();
    OwnToast('success', t('s-logout'))
    setTimeout(() => {
      location.pathname === "/" ? window.location.reload() : navigate("/");
    }, 500);
  }

  const confirmLogout = () => {
    toast((yess) => (
      <div id="toast-interactive" className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow">
        <div className="flex">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg">
            <HiArrowLeftStartOnRectangle className="w-4 h-4" />
            <span className="sr-only">logout icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-black">{t("logout")}</span>
            <div className="mb-2 text-sm font-normal">{t("logout-msg")}</div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <button onClick={() => {
                  logout();
                  toast.dismiss(yess.id);
                }} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300">{t("yes")}</button>
              </div>
              <div>
                <button onClick={() => toast.dismiss(t.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">{t("cancel")}</button>
              </div>
            </div>
          </div>
          <button onClick={() => toast.dismiss(t.id)} className="ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-black rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8">
            <span className="sr-only">Close</span>
            <FaXmark className="w-3 h-3" />
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  if (compo === "from-dashboard") {
    return (
      <div onClick={confirmLogout} className="group col-span-1 cursor-pointer rounded-lg border shadow-sm bg-white p-8 hover:bg-gray-100">
        <div className="mx-auto mb-2 flex h-[54px] max-h-[54px] w-[54px] max-w-[54px] items-center justify-center rounded-full bg-gray-200 group-hover:bg-blue-200 p-2">
          <HiArrowLeftStartOnRectangle className='inline h-7 w-7 text-gray-500 group-hover:text-blue-600' />
        </div>
        <div className="text-center font-semibold text-sm text-gray-500 group-hover:text-blue-600">{t("logout")}</div>
      </div>
    )
  } else { return <div className="w-full h-full" onClick={confirmLogout}>{text}</div> }
}
