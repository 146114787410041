import axios from 'axios';
import { Modal, Spinner } from 'flowbite-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OwnToast from '../../../general additions/OwnToast';
import { useNavigate } from 'react-router';

export default function ReplyMail({ idProp, emailProp }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleReply = () => {
    setEmail(emailProp);
    setOpenModal(true);
  }

  const answerEmail = async (e) => {
    e.preventDefault();
    setLoading(true)
    const data = { email, subject, message, status };
    try {
      await axios.post(`${localhost}/admin/answermail/${idProp}`, data, { headers: { Authorization: `Bearer ${token}` } });
      OwnToast('success', t("reply-user-success"));
      setTimeout(() => { navigate('/admin/dashboard/reports') }, 1500);
      setLoading(false);
    } catch (err) {
      OwnToast('error', t("reply-user-error"));
      console.error(err);
      setLoading(false);
    };
  }

  return (
    <>
      <button onClick={handleReply} className='myborder px-3 py-2 text-black text-sm rounded-md hover:bg-gray-200 w-max'>{t("reply-mail")}</button>

      <Modal size="xl" show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className='flex items-center p-3'><p className='pl-3 text-base font-semibold'>{t("reply-user")}</p></Modal.Header>
        <Modal.Body>
          <form onSubmit={answerEmail}>
            <div className='grid grid-cols-2 gap-2 mb-3'>
              <div className="col-span-2">
                <label className="flex flex-wrap items-center gap-1 mb-2 text-sm font-medium text-black">{t("email")}</label>
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}
                  className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder={t("email")} readOnly />
              </div>
              <div className="col-span-2">
                <label className="flex flex-wrap items-center gap-1 mb-2 text-sm font-medium text-black">{t("subject")}</label>
                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)}
                  className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder={t("subject")} />
              </div>
            </div>
            <div className='grid grid-cols-2 gap-2 mb-3'>
              <div className="col-span-2">
                <label className="flex flex-wrap items-center gap-1 mb-2 text-sm font-medium text-black">Message</label>
                <textarea rows="5" value={message} onChange={(e) => setMessage(e.target.value)}
                  className="focus:ring-blue-700 focus:border-blue-700 placeholder:lowercase bg-gray-50 myborder text-black text-sm rounded-lg block w-full p-2.5" placeholder="message..." required />
              </div>
            </div>
            <div className='flex flex-wrap justify-end gap-2 w-full mt-3'>
              <select onChange={(e) => setStatus(e.target.value)} className='myborder px-3 py-2 text-black text-sm rounded-md hover:bg-gray-200 w-max' required>
                <option value="">{t("set-status")}</option>
                <option className='bg-amber-200 text-amber-700' value="pending">{t("pending")}</option>
                <option className='bg-green-200 text-green-700' value="completed">{t("completed")}</option>
                <option className='bg-red-200 text-red-700' value="cancelled">{t("cancelled")}</option>
              </select>
              {
                loading ? <button disabled className='myborder flex items-center justify-center text-white text-sm rounded-md bg-blue-600 hover:bg-blue-700 w-[131px]'>
                  <Spinner className='w-5 h-5 text-blue-300 fill-white' />
                </button>
                  : <button type='submit' className='myborder px-3 flex items-center justify-center text-white text-sm rounded-md bg-blue-600 hover:bg-blue-700 w-max'>{t("send-msg")}</button>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
