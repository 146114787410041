import { useTranslation } from "react-i18next";
import RatingStars from "../../elements/RatingStars";
import ComentModel from "./ComentModel";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { TimeAgo } from "../../elements/FormatDate";
import { useRefresh } from "../../general additions/RefreshProvider";
import RemoveReviewBtn from "./RemoveReviewBtn";
import { getUserInfo } from "../../auth/getUserInfo";

export default function Reviews({ productId, ratingProd, countRev }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const [userId, setUserId] = useState(0);
  const [reviews, setReviwes] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [btnShowAll, setBtnShowAll] = useState(false);
  const { refreshKey } = useRefresh();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(localhost + "/review/" + productId);
        console.log(res.data);
        const all = showAll ? res.data : res.data.slice(0, 4);
        setBtnShowAll(res.data.length > 4);
        setReviwes(all);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();

    const fetchUserData = async () => {
      try {
        const data = await getUserInfo();
        if (data) {
          setUserId(data.userID);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchUserData();
  }, [showAll, refreshKey]);

  const getProfile = (item) => {
    if (item.fname && item.lname) {
      return item.fname.charAt(0).toUpperCase() + item.lname.charAt(0).toUpperCase();
    } else {
      return item.username.substring(0, 2).toUpperCase();
    }
  }

  return (
    <section className="antialiased">
      <div className="mx-auto max-w-screen-xl px-4 max-md:px-0 2xl:px-0">

        <div className="flex justify-between items-center gap-4 max-md:gap-10 max-md:flex-col">
          <div className="flex text-left flex-col">
            <h2 className="text-2xl mb-2 font-semibold text-black text-left max-md:text-center">{t("reviews")}</h2>

            <div className="mt-2 flex items-center gap-2 sm:mt-0">
              <div className="flex items-center gap-0.5">
                <RatingStars size={4} rating={ratingProd} />
              </div>
              <p className="text-sm font-medium leading-none text-gray-500">({ratingProd.toFixed(1)})</p>
              <p className="text-sm font-medium leading-none text-black underline hover:no-underline"> {countRev} {t("reviews")} </p>
            </div>
          </div>

          <div className="shrink-0 space-y-4">
            <p className="text-2xl font-semibold leading-none text-right max-md:text-center text-black">{ratingProd.toFixed(2)} {t("outof")} 5</p>
            {
              token ? <ComentModel productID={productId} /> : null
            }
          </div>
        </div>

        <div className="mt-6 divide-y space-y-2">
          {
            reviews.map((item, index) => (
              <div key={index} className="w-full lg:p-8 p-6 bg-white rounded-3xl border shadow-sm flex-col justify-start items-end gap-2.5 flex">
                <div className="w-full flex-col justify-start items-start gap-3.5 flex">
                  <div className="w-full justify-between inline-flex">
                    <div className="w-full justify-start items-center gap-2.5 flex">
                      <div className="w-8 h-8 rounded-full justify-start items-start gap-2.5 flex">
                        <p className="flex justify-center items-center h-8 w-8 text-sm font-semibold p-1 rounded-full text-blue-800 bg-blue-100">{getProfile(item)}</p>
                      </div>
                      <div className="flex-col justify-start items-start inline-flex">
                        <h5 className="text-gray-900 text-base font-semibold leading-none mb-0.5">{item.fname && item.lname ? `${item.fname} ${item.lname}` : item.username}</h5>
                        <div className="flex justify-between gap-1 w-full">
                          <RatingStars rating={item.rating} size={3} />
                        </div>
                      </div>
                    </div>
                    <h6 className="text-gray-500 text-xs min-w-36 font-normal leading-none text-end">
                      <TimeAgo dateString={item.created_at} />
                    </h6>
                  </div>

                  <p className="text-gray-800 text-sm font-normal leading-snug">{item.comment}</p>
                  <div className="w-full justify-end items-start gap-6 inline-flex">
                    {
                      userId === item.user_id ?
                        <RemoveReviewBtn rev_id={item.reviewID} />
                        : null
                    }
                  </div>
                </div>
              </div>
            ))
          }

        </div>

        <div className="flex justify-end">
          {btnShowAll ? <button onClick={() => setShowAll(!showAll)} className="my-3 text-sm font-medium text-black hover:text-blue-700">
            {showAll ? t("viewless") : t("viewmore")}
          </button> : null}
        </div>
      </div>
    </section>
  )
}
