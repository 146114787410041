import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HiChevronRight, HiChevronLeft, HiStar } from "react-icons/hi2";
import { useTranslation } from 'react-i18next';
import RatingStars from "../../elements/RatingStars";

export default function Testimonials() {
  const { t } = useTranslation();
  const testimonialsData = [
    { fname: "Joe", lname: "Smith", username: "joesmith01", comment: "Lorem ipsum dolor sit dolor sit dolor sit dolor sit dolor sit dolor sit dolor sit dolor sit amet, consectetur adipiscing elit.", rating: 4 },
    { fname: null, lname: null, username: "johndoe02", comment: "Pellentesque habitant morbi tristique morbi tristique morbi tristique morbi tristique morbi tristique morbi tristique morbi tristique morbi tristique senectus et netus.", rating: 5 },
    { fname: "Emily", lname: "Jones", username: "emilyjones03", comment: "Praesent commodo cursus cursus cursus cursus cursus cursus cursus cursus magna, vel scelerisque nisl consectetur et.", rating: 3 },
    { fname: "Michael", lname: "Brown", username: "michaelbrown04", comment: "Aenean lacinia bibendum nulla bibendum nulla bibendum nulla bibendum nulla bibendum nulla bibendum nulla bibendum nulla bibendum nulla sed consectetur.", rating: 4 },
    { fname: "Sarah", lname: "Davis", username: "sarahdavis05", comment: "Vestibulum id ligula porta ligula porta ligula porta ligula porta ligula porta ligula porta ligula porta ligula porta felis euismod semper.", rating: 5 },
    { fname: null, lname: "Miller", username: "miller06", comment: "Curabitur blandit tempus porttitortempus porttitortempus porttitortempus porttitortempus porttitortempus porttitortempus porttitortempus porttitor.", rating: 3 },
    { fname: "William", lname: null, username: "william07", comment: "Donec id elit non elit non elit non elit non elit non elit non elit non elit non mi porta gravida at eget metus.", rating: 4 },
    { fname: "Jessica", lname: "Garcia", username: "jessicagarcia08", comment: "Nullam quis risus eget risus eget risus eget risus eget risus eget risus eget risus eget risus eget urna mollis ornare vel eu leo.", rating: 5 },
    { fname: "James", lname: "Martinez", username: "jamesmartinez09", comment: "Maecenas faucibus mollis interdummollis interdummollis interdummollis interdummollis interdummollis interdummollis interdummollis interdum.", rating: 3 },
    { fname: null, lname: null, username: "martin10", comment: "Etiam porta sem malesuada sem malesuada sem malesuada sem malesuada sem malesuada sem malesuada sem malesuada sem malesuada magna mollis euismod.", rating: 4 },
    { fname: "Linda", lname: "Rodriguez", username: "lindarodriguez11", comment: "Cras mattis consectetur purus consectetur purus consectetur purus consectetur purus consectetur purus consectetur purus consectetur purus consectetur purus sit amet fermentum.", rating: 5 },
    { fname: "Robert", lname: "Wilson", username: "robertwilson12", comment: "Lorem ipsum dolor sit dolor sit dolor sit dolor sit dolor sit dolor sit dolor sit dolor sit amet, consectetur adipiscing elit.", rating: 3 },
    { fname: "Karen", lname: null, username: "karen13", comment: "Vivamus sagittis lacus vel lacus vel lacus vel lacus vel lacus vel lacus vel lacus vel lacus vel augue laoreet rutrum faucibus dolor auctor.", rating: 4 },
    { fname: "Richard", lname: "Lee", username: "richardlee14", comment: "Integer posuere erat a erat a erat a erat a erat a erat a erat a erat a ante venenatis dapibus posuere velit aliquet.", rating: 5 },
    { fname: null, lname: "Walker", username: "walker15", comment: "Sed posuere consectetur est consectetur est consectetur est consectetur est consectetur est consectetur est consectetur est consectetur est at lobortis.", rating: 3 },
    { fname: "Thomas", lname: null, username: "thomas16", comment: "Vestibulum id ligula porta ligula porta ligula porta ligula porta ligula porta ligula porta ligula porta ligula porta felis euismod semper.", rating: 4 },
    { fname: "Patricia", lname: "Harris", username: "patriciaharris17", comment: "Curabitur blandit tempus porttitortempus porttitortempus porttitortempus porttitortempus porttitortempus porttitortempus porttitortempus porttitor.", rating: 5 },
    { fname: "Daniel", lname: "Clark", username: "danielclark18", comment: "Donec id elit non elit non elit non elit non elit non elit non elit non elit non mi porta gravida at eget metus.", rating: 3 },
    { fname: "Barbara", lname: "Young", username: "barbarayoung19", comment: "Nullam quis risus eget risus eget risus eget risus eget risus eget risus eget risus eget risus eget urna mollis ornare vel eu leo.", rating: 4 },
    { fname: null, lname: "King", username: "king20", comment: "Maecenas faucibus mollis interdummollis interdummollis interdummollis interdummollis interdummollis interdummollis interdummollis interdum.", rating: 5 }
  ];

  const getProfile = (item) => {
    if (item.fname && item.lname) {
      return item.fname.charAt(0).toUpperCase() + item.lname.charAt(0).toUpperCase();
    } else {
      return item.username.substring(0, 2).toUpperCase();
    }
  }

  return (
    <section className="container py-10">
      <div>
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-5 bg-gray-300 border-0" />
            <span className="text-lg max-md:text-base font-semibold absolute px-3 text-black -translate-x-1/2 bg-gray-100 left-1/2">{t("testimonials")}</span>
          </div>
          <p className="text-sm font-normal text-gray-500 max-w-md md:max-w-2xl mx-auto">
            {t("testimonials-1")}
          </p>
        </div>

        <div className="mt-5 h-60 max-md:h-[700px]">
          <Carousel customLeftArrow={<CustomLeftArrow />} customRightArrow={<CustomRightArrow />} autoPlay={true} additionalTransfrom={0} arrows autoPlaySpeed={3000}
            centerMode={false} className="" containerClass="container-with-dots" dotListClass="" draggable focusOnSelect={false} infinite itemClass=""
            keyBoardControl minimumTouchDrag={80} pauseOnHover renderArrowsWhenDisabled={false} renderButtonGroupOutside={false} renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 3,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }} rewind={false} rewindWithAnimation={false} rtl={false} shouldResetAutoplay showDots={false} sliderClass="" slidesToSlide={1} swipeable>
            {
              testimonialsData.map((item, index) => (
                <div key={index} className="min-h-full p-2">
                  <div className="w-full bg-white border p-4 rounded-lg shadow-sm">
                    <div className="flex flex-wrap gap-2 text-black font-medium items-center mb-2">
                      <p className="flex justify-center items-center h-8 w-8 text-sm font-semibold p-1 rounded-full text-blue-800 bg-blue-100">{getProfile(item)}</p>
                      <p className="text-sm font-semibold">{item.fname && item.lname ? `${item.fname} ${item.lname}` : item.username}</p>
                    </div>
                    <RatingStars rating={item.rating} size={4} />
                    <p className="text-xs font-medium text-gray-500 mt-2 min-h-16">{item.comment}</p>
                  </div>
                </div>
              ))
            }
          </Carousel>
        </div>
      </div>
    </section>
  );
};

// Buttons :
const CustomLeftArrow = ({ onClick }) => (
  <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer h-full w-24 flex items-center justify-start bg-gradient-to-r from-gray-100 to-transparent" onClick={onClick}>
    <HiChevronLeft className='h-8 w-8 text-black' />
  </div>
);

const CustomRightArrow = ({ onClick }) => (
  <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer h-full w-24 flex items-center justify-end bg-gradient-to-l from-gray-100 to-transparent" onClick={onClick}>
    <HiChevronRight className='h-8 w-8 text-black' />
  </div>
);