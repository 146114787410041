import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import OwnToast from "../general additions/OwnToast";
import { useTranslation } from "react-i18next";
import TopBar from "../ecrans/additions/TopBar";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const signin = async (e) => {
    e.preventDefault();
    try {
      const userData = { login, password, remember_me: rememberMe };
      const loginResponse = await axios.post(`${localhost}/login`, userData);

      localStorage.setItem('token', loginResponse.data.access_token);
      OwnToast('success', t('s-login'));

      setTimeout(() => { navigate("/") }, 500);

    } catch (err) {
      OwnToast('error', t('e-login'));
    }
  }

  return (
    <section className="bg-gray-100">
      <TopBar />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="flex flex-wrap items-end text-4xl text-black lowercase font-bold mb-6">Shared<span className="text-blue-600">Store</span></div>
        <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 sm:p-8">
            <h1 className="text-lg font-bold leading-tight tracking-tight text-black">
              {t("login-1")}
            </h1>
            <form onSubmit={signin} className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="login" className="block mb-2 text-sm font-medium text-black">{t("username-email")}</label>
                <input type="text" value={login} onChange={(e) => setLogin(e.target.value)} name="login" id="login" className="text-sm bg-gray-50 myborder text-black rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder={t("username-email")} />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-black">{t("password")}</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" id="password" placeholder="••••••••" className="text-sm bg-gray-50 myborder text-black rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input id="remember" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-200 focus:ring-3 focus:ring-blue-300" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className="text-gray-500">{t("remember-me")}</label>
                  </div>
                </div>
                <a href="/forgot-password" className="text-sm font-medium text-blue-600 hover:underline">{t("forgot-password")}</a>
              </div>
              <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t("login")}</button>
              <p className="flex flex-wrap gap-1 text-sm font-light text-gray-500">
                {t("msg-login")} <a href="/register" className="font-medium text-blue-600 hover:underline">{t("register")}</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
