import React from 'react'

export default function LocationIcon() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="50px" height="50px" viewBox="0 0 286.000000 286.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,286.000000) scale(0.100000,-0.100000)"
        className='fill-black group-hover:fill-white' stroke="none">
        <path d="M1350 2714 c-227 -44 -389 -146 -498 -313 -194 -300 -161 -661 113
   -1208 65 -130 78 -164 66 -167 -9 -3 -52 -12 -96 -20 -105 -19 -283 -84 -364
   -131 -268 -158 -261 -407 16 -562 88 -49 275 -110 407 -134 600 -105 1287 27
   1447 279 90 142 42 290 -130 400 -89 58 -269 126 -385 148 -44 8 -89 17 -98
   20 -14 4 -3 33 71 182 175 349 241 589 228 824 -11 185 -74 332 -200 464 -91
   96 -191 159 -304 190 -86 24 -223 37 -273 28z m171 -184 c30 -5 90 -28 135
   -49 237 -117 348 -374 288 -669 -48 -241 -206 -584 -431 -934 -41 -65 -78
   -118 -82 -118 -10 0 -103 140 -183 273 -118 198 -190 345 -271 557 -41 108
   -77 287 -77 386 0 223 113 410 304 505 115 56 204 70 317 49z m-277 -1814
   c119 -179 144 -206 186 -206 43 0 70 30 188 209 l95 143 49 -6 c101 -14 315
   -74 392 -111 104 -50 156 -100 156 -147 0 -29 -8 -43 -42 -73 -59 -53 -189
   -111 -326 -145 -194 -47 -260 -55 -512 -55 -145 0 -266 5 -315 13 -329 54
   -565 164 -565 264 0 42 55 93 151 140 82 40 134 56 290 92 164 38 144 48 253
   -118z"/>
        <path d="M1383 2249 c-88 -16 -160 -78 -192 -164 -52 -139 3 -285 131 -349
   180 -90 382 60 366 269 -14 162 -151 272 -305 244z m80 -180 c61 -31 76 -107
   31 -153 -37 -37 -86 -35 -125 3 -49 49 -33 125 31 150 33 13 38 13 63 0z"/>
      </g>
    </svg>

  )
}
