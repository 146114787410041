import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle } from 'react-icons/hi2'
import { LoadingShopPart } from '../../general additions/LoadingElements';
import axios from 'axios';
import TransactionsTable from './history orders/TransactionsTable';

export default function TransactionsList() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [myTransactions, setMyTransactions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${localhost}/getallpaymentsbyuser`, { headers: { 'Authorization': `Bearer ${token}` } });
      setMyTransactions(res.data);
      // console.log(res.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50">
      <h1 className="pt-2 text-xl text-black font-semibold">Transactions</h1>
      <p className="text-sm text-slate-600">{t("all-transactions")}</p>
      <hr className="mt-4 mb-6" />
      {
        loading ?
          <div className='flex justify-center items-center w-full min-h-[40vh]'><LoadingShopPart sizeProp={150} /></div>
          : myTransactions.length > 0 ? <TransactionsTable data={myTransactions} />
            : <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50" role="alert">
              <HiExclamationCircle className='flex-shrink-0 inline w-5 h-5 me-2' />
              <span className="sr-only">Info</span>
              <div className='flex flex-wrap gap-1'>
                {t("all-transactions-1")} <a href='/shop' className='underline'>{t("all-purchases-2")}</a>
              </div>
            </div>
      }
    </div>
  )
}
