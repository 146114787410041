import React from 'react'
import { notfound } from './assets/images'
import { useTranslation } from 'react-i18next'
import TopBar from './ecrans/additions/TopBar';
import Header from './ecrans/additions/Header';
import Footer from './ecrans/additions/Footer';

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <section className=''>
      <TopBar />
      <Header />
      <div className='bg-gray-100'>
        <div className="container">
          <div className="py-12 px-4 mx-auto">
            <div className="flex flex-col justify-center items-center text-center">
              <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-8xl text-blue-600">404</h1>
              <p className="mb-4 text-2xl tracking-tight font-bold text-black md:text-3xl">{t("error-1")}</p>
              <p className="mb-4 text-base font-light text-gray-500">{t("error-2")}</p>
              <img src={notfound} className='mx-auto max-w-lg max-md:max-w-md max-sm:w-64 h-auto' alt="not found" />
              <a href="/" className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">{t("error-3")}</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}
