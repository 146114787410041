import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { notresultsfound } from "../../assets/images";
import { LoadingShopPart } from "../../general additions/LoadingElements";
import axios from "axios";
import { FormatDateForcomment, FormatDateTable, FormatDateTableUsers } from "../../elements/FormatDate";
import ReportView from "./views/ReportView";

export default function ReportsList() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterOption, setFilterOption] = useState("all");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(`${localhost}/admin/reports`, { headers: { Authorization: `Bearer ${token}` } });
        setReports(response.data);
        setFilteredReports(response.data);
        setLoading(false);
        console.log(response.data);
      } catch (err) { console.error(err) };
    };
    fetchReports();
  }, []);

  const handleFilter = (status) => {
    if (status !== 'all') {
      const filterData = reports.filter((item) => item.status.toLowerCase() === status);
      setFilteredReports(filterData);
    } else {
      setFilteredReports(reports);
    }
  };

  return (
    <div className="antialiased">
      <div className="flex justify-between gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("reports")}</p>
      </div>

      <div className="">
        <div className="rounded-lg bg-white mt-3 border shadow-sm p-3 flex justify-end">
          <select onChange={(e) => handleFilter(e.target.value)}
            className="max-w-36 rounded-lg myborder bg-white px-2 py-1.5 text-sm font-medium text-black hover:border-[#D1D5DB] focus:z-10 focus:outline-none focus:border-gray-200 focus:ring-4 focus:ring-gray-100">
            <option value="all">{t("all")}</option>
            <option value="pending">{t("pending")}</option>
            <option value="completed">{t("completed")}</option>
            <option value="cancelled">{t("cancelled")}</option>
          </select>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto rounded-lg bg-white mt-3 border shadow-sm">
          {loading ? (
            <div className="flex justify-center items-center h-[50vh] w-full">
              <LoadingShopPart />
            </div>
          ) : filteredReports.length > 0 ? (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 bg-gray-50">
                <tr>
                  <th className="px-6 py-3">{t("report")} ID</th>
                  <th className="px-6 py-3">{t("fullname")}</th>
                  <th className="px-6 py-3">{t("email")}</th>
                  <th className="px-6 py-3">{t("subject")}</th>
                  <th className="px-6 py-3">Date</th>
                  <th className="px-6 py-3">Status</th>
                  <th className="px-6 py-3">{t("view")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredReports.map((item, index) => (
                    <tr key={index} className="bg-white border-b">
                      <th scope="row" className="px-6 py-4 font-semibold text-gray-900 whitespace-nowrap">
                        {item.uniqueReport}
                      </th>
                      <td className="px-6 py-4">{item.name}</td>
                      <td className="px-6 py-4">{item.email}</td>
                      <td className="px-6 py-4 max-w-60">{item.subject ? item.subject : '--'}</td>
                      <td className="px-6 py-4"><FormatDateTableUsers dateString={item.created_at} /> </td>
                      <td className="px-6 py-4 text-sm font-medium">
                        {item.status.toLowerCase() === "completed" ?
                          <p className='text-green-600 bg-green-200 rounded-md px-2 text-center w-max capitalize'>{t("completed")}</p>
                          : item.status.toLowerCase() === "cancelled" ?
                            <p className='text-red-600 bg-red-200 rounded-md px-2 text-center w-max capitalize'>{t("cancelled")}</p>
                            : item.status.toLowerCase() === "pending" ? <p className='text-yellow-600 bg-yellow-200 rounded-md px-2 text-center w-max capitalize'>{t("pending")}</p> : null}
                      </td>
                      <td className="px-6 py-4">
                        <a href={`report/${item.id}`} className='myborder px-2 py-1 text-black text-sm rounded-md hover:bg-gray-100 w-max'>{t("view")}</a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center items-center py-10 text-gray-500">
              <div className="col-span-4 text-center text-black flex flex-col gap-6 justify-center items-center min-h-[40vh]">
                <img src={notresultsfound} className="w-48 h-auto" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
