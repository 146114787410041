import { Drawer } from "flowbite-react";
import { useState } from "react";
import { FaMagnifyingGlass, FaXmark } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ProductCard from "../../elements/ProductCard";
import { LoadingShopPart } from "../../general additions/LoadingElements";
import { notresultsfound } from "../../assets/images";

export default function Search() {
  const { t, i18n } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;

  // State variables
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [products, setProducts] = useState([]);

  // Function to close the drawer
  const handleClose = () => setIsOpen(false);

  // Function to search products based on input value
  const searchProducts = async () => {
    if (inputValue.trim() === "") return;

    try {
      setIsOpen(true);
      setLoading(true);

      // Make API call to search for products
      const response = await axios.get(`${localhost}/products/search`, {
        params: { query: inputValue, language: i18n.language },
      });

      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching for products:", error);
    }
  };

  return (
    <>
      {/* Button to open search drawer */}
      <button onClick={() => setIsOpen(true)} className="md:hidden">
        <FaMagnifyingGlass className="w-[21px] h-[21px] pt-0.5 hover:text-blue-500" />
      </button>

      <button onClick={() => setIsOpen(true)} className="max-md:hidden flex justify-center items-center h-9 w-9 bg-blue-600 focus:bg-blue-700 rounded-md text-white transition-all">
        <FaMagnifyingGlass className="w-[18px] h-[18px]" />
      </button>

      {/* Drawer for search input and results */}
      <Drawer open={isOpen} onClose={handleClose} position="bottom" className="p-0 h-[80vh]">
        <Drawer.Items className="container p-4">
          <div className="flex justify-around gap-1">
            {/* Search input field */}
            <input type="search" value={inputValue} onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && searchProducts()}
              className="rounded-md myborder px-2 py-1.5 text-sm text-black"
              placeholder={t("search-input")}
            />
            {/* Close button */}
            <button onClick={handleClose} className="flex justify-center items-center bg-blue-600 hover:bg-blue-700 text-white rounded-md w-[38px] h-[38px]">
              <FaXmark className="w-5 h-5" />
            </button>
          </div>

          {/* Display loading or search results */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-4 py-4">
            {loading ? (
              // Loading spinner when fetching data
              <div className="flex justify-center items-center min-h-[60vh] col-span-4">
                <LoadingShopPart sizeProp={150} />
              </div>
            ) : products.length > 0 ? (
              // Display product cards if results are found
              products.map((item, index) => (
                <ProductCard key={index} data={item} typeProp="notJson" />
              ))
            ) : (
              // Message if no search results are found
              <div className="col-span-4 text-center text-black flex flex-col gap-6 justify-center items-center min-h-[50vh]">
                <img src={notresultsfound} className="w-48 h-auto"/>
              </div>
            )}
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
