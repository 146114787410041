import { HiExclamationCircle } from 'react-icons/hi2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmedOrders from './history orders/ConfirmedOrders';
import axios from 'axios';
import { LoadingShopPart } from '../../general additions/LoadingElements';

export default function HistoryOrders() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;

  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [myOrders, setMyOrders] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await axios.get(localhost + "/myconfirmedorders", { headers: { 'Authorization': `Bearer ${token}` } });
        setMyOrders(res.data);
        setLoading(false);
      }
      fetchData();
    } catch (err) {
      // 
    }
  }, []);

  return (
    <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50">
      <h1 className="pt-2 text-xl text-black font-semibold">{t("purchases")}</h1>
      <p className="text-sm text-slate-600">{t("all-purchases")}</p>
      <hr className="mt-4 mb-6" />
      {
        loading ?
          <div className='flex justify-center items-center w-full min-h-[40vh]'><LoadingShopPart sizeProp={150} /></div>
          : myOrders.length > 0 ? <ConfirmedOrders />
            : <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50" role="alert">
              <HiExclamationCircle className='flex-shrink-0 inline w-5 h-5 me-2' />
              <span className="sr-only">Info</span>
              <div className='flex flex-wrap gap-1'>
                {t("all-purchases-1")} <a href='/shop' className='underline'>{t("all-purchases-2")}</a>
              </div>
            </div>
      }
    </div>
  )
}
