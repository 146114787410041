import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { CiLock } from "react-icons/ci";
import TopBar from '../ecrans/additions/TopBar';
import i18next from 'i18next';
import { Modal, Spinner } from 'flowbite-react';
import { IoMdCheckmark } from "react-icons/io";
import OwnToast from '../general additions/OwnToast';

export default function ForgotPassword() {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setOpenModal(true);
    const language = i18next.language;
    axios.post(localhost + '/forgot-password', { email, language })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        OwnToast('error', t("er-forgotpsw"))
        setOpenModal(false);
      });
  };

  return (
    <>
      <section className="bg-gray-100 min-h-screen">
        <TopBar />
        <div className="flex flex-col items-center justify-center p-4 mx-auto h-[85vh] lg:py-0">
          <div className="w-full p-6 shadow-sm myborder bg-white rounded-lg md:mt-0 sm:max-w-md">
            <div className='flex justify-center w-full'>
              <div className='border-[2px] border-blue-600 rounded-full p-3'>
                <CiLock className='h-16 w-16 text-blue-600' />
              </div>
            </div>

            <div className='text-center mt-4 px-6'>
              <h1 className="mb-1 text-base leading-tight tracking-tight text-black">{t("forgot-password-title")}</h1>
              <p className="font-light text-sm text-gray-500">{t("forgot-password-text")}</p>
            </div>

            <form onSubmit={handleForgotPassword} className="mt-4 space-y-3">
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-black">{t("email")}</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 myborder text-black text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder={t("email")} required />
              </div>
              <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Reset password</button>
            </form>
          </div>
          <div>
            <div className="inline-flex items-center justify-center w-full mt-6">
              <hr className="w-64 h-px my-4 bg-gray-500 border-0" />
              <span className="absolute px-3 font-medium text-gray-700 -translate-x-1/2 bg-gray-100 left-1/2 text-sm">{t("or")}</span>
            </div>
            <a href="/register" className='text-center text-sm text-black hover:text-blue-700'>{t("create-acc")}</a>
          </div>
        </div>
      </section>

      <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body className='px-4 pb-4 text-center'>
          {
            loading ? <div className='h-60 flex items-center justify-center w-full'><Spinner className='w-8 h-8 text-gray-400 fill-blue-600' /></div>
              : <div>
                <div className='rounded-full p-2 mx-auto mb-3 bg-green-100 w-max'>
                  <div className='rounded-full bg-green-300 text-white w-max p-2'>
                    <IoMdCheckmark className='w-10 h-10 max-sm:h-8 max-sm:w-8 pb-0.5' />
                  </div>
                </div>

                <h2 className='text-lg max-sm:text-base text-black font-semibold mb-2'>{t("notif-reset-link-title")}</h2>
                <p className='text-sm max-sm:text-xs px-2'>{t("notif-reset-link-text")}</p>

                <div className='w-full flex justify-center gap-2 font-medium text-sm max-sm:text-xs mt-5'>
                  <a href='/login' className='px-3 py-2 bg-green-400 hover:bg-green-500 text-white rounded-md'>{t("iunderstand")}</a>
                </div>
              </div>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};