import { HiXMark, HiTrash } from "react-icons/hi2";
import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useRefresh } from "../../general additions/RefreshProvider";
import OwnToast from "../../general additions/OwnToast";

export default function DeleteDiscount({ discount_id, typeProp }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { triggerRefresh } = useRefresh();
  const route = typeProp === "category" ? "/admin/categories/" : typeProp === "license" ? "/admin/licenses/" : "/admin/discounts/";

  const remove = () => {
    axios.delete(localhost + route + discount_id, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        triggerRefresh();
        OwnToast('success', t("delete-" + typeProp + "-success"));
      })
      .catch((err) => { console.error(err); OwnToast('error', t("delete-" + typeProp + "-error")); });
  }

  const confirmRemove = () => {
    toast((toa) => (
      <div id="toast-interactive" className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow">
        <div className="flex">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-600 bg-red-200 rounded-lg">
            <HiTrash className="w-5 h-5" />
            <span className="sr-only">delete icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-black">{t("remove-" + typeProp)}</span>
            <div className="mb-2 text-sm font-normal">{t("remove-" + typeProp + "-txt")}</div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <button onClick={() => {
                  remove();
                  toast.dismiss(toa.id);
                }} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300">{t("yes")}</button>
              </div>
              <div>
                <button onClick={() => toast.dismiss(toa.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">{t("cancel")}</button>
              </div>
            </div>
          </div>
          <button onClick={() => toast.dismiss(toa.id)} className="h-6 w-6 ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-black rounded-lg focus:ring-2 focus:ring-gray-300 hover:bg-gray-100 inline-flex">
            <span className="sr-only">Close</span>
            <HiXMark className="w-4 h-4" />
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  return (
    <button onClick={confirmRemove} className='flex flex-wrap items-center gap-1 font-semibold myborder px-2 py-1.5 text-red-600 text-sm rounded-md bg-white hover:bg-red-100'>
      <HiTrash className='w-4 h-4' />
      {t("delete")}
    </button>
  )
}
