import { useTranslation } from "react-i18next";
import { microsoftlogo, paypallogo } from "../../assets/icons";
import FacebookIcon from "../../assets/icons/FacebookIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import PinterestIcon from "../../assets/icons/PinterestIcon";
import TwitterIcon from "../../assets/icons/TwitterIcon";

export default function Footer() {
  const { t } = useTranslation();

  const paymentsLogos = [
    paypallogo,
  ];

  const footerBrand = [
    "Sharedstore",
    "+212 611-223344",
    "support@sharedstore.ma",
    "Florida Centre Park 2 Lot 7, Casablanca",
  ];
  const socialList = [
    { link: "/#_", icon: <FacebookIcon /> },
    { link: "/#_", icon: <InstagramIcon /> },
    { link: "/#_", icon: <PinterestIcon /> },
    { link: "/#_", icon: <TwitterIcon /> },
  ];
  const footerList1 = [
    { link: "/#_", name: "footer-list1.item1" },
    { link: "/#_", name: "footer-list1.item2" },
    { link: "/#_", name: "footer-list1.item3" },
    { link: "/#_", name: "footer-list1.item4" },
    { link: "/#_", name: "footer-list1.item5" },
    { link: "/#_", name: "footer-list1.item6" },
    { link: "/#_", name: "footer-list1.item7" },
  ];
  const footerList2 = [
    { link: "/#_", name: "footer-list2.item1" },
    { link: "/#_", name: "footer-list2.item2" },
    { link: "/#_", name: "footer-list2.item3" },
    { link: "/#_", name: "footer-list2.item4" },
    { link: "/#_", name: "footer-list2.item5" },
  ];


  return (
    <footer className="footer bg-gray-900 text-white">

      <div className="footer-top text-sm" >
        <div className="container">

          <div className="footer-brand">
            <a href="/" className="flex flex-wrap items-end text-2xl mb-3 text-white lowercase font-bold">Shared<span className="text-blue-600">Store</span></a>
            <div className="footer-text">
              {footerBrand.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
            </div>
            <ul className="social-list">
              {socialList.map((item, index) =>
                (<li><a key={index} className="social-link" href={item.link}>{item.icon}</a></li>)
              )}
            </ul>
          </div>

          <ul className="footer-list">
            <li><p className="footer-list-title">{t("links")}</p></li>
            {footerList1.map((item, index) => (
              <li><a key={index} href={item.link} className="footer-link">{t(item.name)}</a></li>
            ))}
          </ul>

          <ul className="footer-list">
            <li><p className="footer-list-title">{t("help")}</p></li>
            {footerList2.map((item, index) => (
              <li><a key={index} href={item.link} className="footer-link">{t(item.name)}</a></li>
            ))}
          </ul>

          <ul className="footer-list">
            <li><p className="footer-list-title">{t("partners")}</p></li>
            <li><img className="h-20 w-auto" src={microsoftlogo} alt="Microsoft" /></li>
          </ul>

        </div>
      </div>

      <div className="footer-bottom py-2">
        <div className="container">

          <p className="copyright text-sm">
            &copy; 2024 <a href="/" className="text-white">Shared System</a>, {t("all-rights")}.
          </p>

          <ul className="footer-bottom-list text-sm">

            <div className="payment max-md:mb-14">
              <p className="payment-title">{t("secure-payment")}</p>

              <div className="flex flex-wrap gap-4">
                {
                  paymentsLogos.map((item, index) => (
                    <img key={index} src={item} alt="Online payments" className="h-8 w-auto" />
                  ))
                }
              </div>
            </div>

          </ul>

        </div>
      </div>

    </footer>
  )
}