import React from 'react'
import { useTranslation } from 'react-i18next';
import TopBar from '../additions/TopBar';
import Header from '../additions/Header';
import Footer from '../additions/Footer';
import { ImCheckmark } from "react-icons/im";

export default function ThankYou() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />
      <Header />
      <div className='bg-gray-100 text-black min-h-[80vh] flex items-center'>
        <div className="container">
          <div className='flex flex-col items-center gap-3 text-center'>
            <h1 className='text-8xl max-sm:text-7xl font-bold uppercase'>{t("thankyou")}!</h1>
            <ImCheckmark className='h-36 w-36 text-green-500' />
            <div className='text-lg max-sm:text-base font-medium'>
              <p>{t("confirmation-purchase-text-1")}</p>
              <p>{t("confirmation-purchase-text-2")}</p>
              <p>{t("confirmation-purchase-text-3")}</p>
            </div>
            <a href="/account/purchases" className='text-green-500 font-semibold hover:underline'>{t("browse-purchase")}</a>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}
