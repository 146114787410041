import { Route, Routes } from "react-router";
import "./assets/styles.css";
import "./assets/font/font.css";

import Home from "./ecrans/home/Home";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Shop from "./ecrans/shop/Shop";
import ContactUs from "./ecrans/about & contact/ContactUs";
import AboutUs from "./ecrans/about & contact/AboutUs";
import DetailsProduct from "./ecrans/details product/DetailsProduct";
import Order from "./ecrans/order/Order";
import CheckoutForm from "./ecrans/order/CheckoutForm";
import { RefreshProvider } from "./general additions/RefreshProvider";
import MyFavorites from "./ecrans/myfavorites/MyFavorites";
import { AdminRoute, UserRoute } from "./auth/Protection";
import NotFound from "./NotFound";
import Settings from "./ecrans/dashboard/Settings";
import OrderConfiramtion from "./ecrans/order/OrderConfiramtion";
import ThankYou from "./ecrans/order/ThankYou";
import SidebarLayout from "./admin/dashboard/SidebarLayout";
import { ModalProvider } from "./general additions/ModalProvider";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import AccountSettings from "./admin/account/AccountSettings";

function App() {
  return (
    <RefreshProvider>
      <ModalProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />

          <Route path="/product/:slug" element={<DetailsProduct />} />
          <Route path="/order" element={<Order />} />

          <Route element={<UserRoute />}>
            <Route path="/account/:section" element={<Settings />} />
            <Route path="/account/favorites" element={<MyFavorites />} />
            <Route path="/order/checkout" element={<CheckoutForm />} />
            <Route path="/order/thankyou" element={<ThankYou />} />
          </Route>

          {/* Admin : */}
          <Route element={<AdminRoute />}>
            <Route path="/admin/dashboard/*" element={<SidebarLayout />} />
            <Route path="/admin/settings" element={<AccountSettings />} />
          </Route>

          <Route path="/order/confirmorder" element={<OrderConfiramtion />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ModalProvider>
    </RefreshProvider>
  );
}

export default App;
