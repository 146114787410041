import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BsSearch } from "react-icons/bs";
import { useRefresh } from '../../general additions/RefreshProvider';
import { LoadingShopPart } from '../../general additions/LoadingElements';
import { FormatDateTableUsers } from '../../elements/FormatDate';
import { notresultsfound } from '../../assets/images';

export default function UsersList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { refreshKey } = useRefresh();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Fetch users data
  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await axios.get(`${localhost}/admin/users`, { headers: { Authorization: `Bearer ${token}` } });
        setUsers(res.data);
        setFilteredUsers(res.data);
        setLoading(false);
      }
      fetchData();
    } catch (err) {
      // 
    }
  }, [refreshKey]);

  // Handle search
  const handleSearch = (e) => {
    e.preventDefault();
    const lowercasedSearch = search.toLowerCase();
    const filtered = users.filter((user) =>
      user.fname?.toLowerCase().includes(lowercasedSearch) ||
      user.lname?.toLowerCase().includes(lowercasedSearch) ||
      user.email?.toLowerCase().includes(lowercasedSearch) ||
      user.phone?.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredUsers(filtered);
  };

  return (
    <div className="antialiased">
      <div className="flex justify-start gap-2 items-center max-sm:p-1.5 mb-3 max-sm:mb-0">
        <p className="text-black font-semibold text-xl max-sm:text-lg">{t("customers")}</p>
      </div>

      <div className="">
        <div className="rounded-lg bg-white mt-3 border shadow-sm p-3 flex justify-between max-sm:flex-col gap-2 items-center">
          <form onSubmit={handleSearch} className="flex gap-1 w-full">
            <input className="myborder rounded-md text-sm w-full text-black placeholder:text-gray-400 py-1.5 px-2" placeholder={t("search-input-customers")} type="text"
              value={search} onChange={(e) => setSearch(e.target.value)} />
            <button type="submit" className="h-[34px] min-w-[34px] flex justify-center items-center rounded-md bg-blue-600 hover:bg-blue-700 text-white">
              <BsSearch className="w-5 h-5" />
            </button>
          </form>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto rounded-lg bg-white mt-3 border shadow-sm">
          {loading ? (
            <div className='flex justify-center items-center h-[50vh] w-full'>
              <LoadingShopPart />
            </div>
          ) : filteredUsers.length > 0 ? (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 bg-gray-50">
                <tr>
                  <th className="px-6 py-3">{t("user")} ID</th>
                  <th className="px-6 py-3">{t("fullname")}</th>
                  <th className="px-6 py-3">{t("username")}</th>
                  <th className="px-6 py-3">{t("email")}</th>
                  <th className="px-6 py-3">{t("phone")}</th>
                  <th className="px-6 py-3">{t("registration-date")}</th>
                  <th className="px-6 py-3">{t("view")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((item, index) => (
                  <tr key={index} className="bg-white border-b">
                    <th scope="row" className="px-6 py-4 font-semibold text-gray-900 whitespace-nowrap">
                      {item.uniqueID}
                    </th>
                    <td className="px-6 py-4">{item.fname && item.lname ? `${item.fname} ${item.lname}` : '- -'}</td>
                    <td className="px-6 py-4">{item.username}</td>
                    <td className="px-6 py-4">{item.email}</td>
                    <td className="px-6 py-4">{item.phone || '- -'}</td>
                    <td className="px-6 py-4">
                      <FormatDateTableUsers dateString={item.created_at} />
                    </td>
                    <td className="px-6 py-4">
                      <button className='myborder px-2 py-1 text-black text-sm rounded-md hover:bg-gray-100'>{t("view")}</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center items-center py-10 text-gray-500">
              <div className="col-span-4 text-center text-black flex flex-col gap-6 justify-center items-center min-h-[40vh]">
                <img src={notresultsfound} className="w-48 h-auto" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
