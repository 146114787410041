import axios from 'axios';
import React from 'react'
import toast from 'react-hot-toast';
import OwnToast from '../../general additions/OwnToast';
import { useTranslation } from 'react-i18next';
import { HiTrash, HiXMark } from 'react-icons/hi2';
import { useRefresh } from '../../general additions/RefreshProvider';

export default function RemoveReviewBtn({ rev_id }) {
  const { t } = useTranslation();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem("token");
  const { triggerRefresh } = useRefresh();

  const remove = () => {
    axios.delete(localhost + "/review/" + rev_id, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(() => {
        triggerRefresh();
        OwnToast('success', t("s-remove-rev"));
      }).catch((err) => OwnToast('error', t("e-remove-rev")));
  }

  const confirmRemove = () => {
    toast((toa) => (
      <div id="toast-interactive" className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow">
        <div className="flex">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-600 bg-red-200 rounded-lg">
            <HiTrash className="w-5 h-5" />
            <span className="sr-only">delete icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-black">{t("removeorder")}</span>
            <div className="mb-2 text-sm font-normal">{t("removeorder-1")}</div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <button onClick={() => {
                  remove();
                  toast.dismiss(toa.id);
                }} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300">{t("yes")}</button>
              </div>
              <div>
                <button onClick={() => toast.dismiss(toa.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">{t("cancel")}</button>
              </div>
            </div>
          </div>
          <button onClick={() => toast.dismiss(toa.id)} className="h-6 w-6 ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-black rounded-lg focus:ring-2 focus:ring-gray-300 hover:bg-gray-100 inline-flex">
            <span className="sr-only">Close</span>
            <HiXMark className="w-4 h-4" />
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  return (
    <button onClick={confirmRemove} className="flex flex-wrap items-center text-sm gap-1 py-2 px-3 text-red-600 hover:text-red-700 bg-red-100 hover:bg-red-200 rounded-lg border border-red-600">
      <HiTrash className="w-4 h-4" />
      {t("delete")}
    </button>
  )
}
