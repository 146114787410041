import { Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineHeart } from 'react-icons/hi2';
import { IoWarningOutline } from "react-icons/io5";

export default function NotFavoriteBtn() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <button className='' onClick={() => setOpenModal(true)}><HiOutlineHeart className="w-5 h-5" /></button>

      <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body className='px-4 pb-4 text-center'>
          <div>
            <div className='rounded-full p-2 mx-auto mb-3 bg-yellow-100 w-max'>
              <div className='rounded-full bg-yellow-300 text-white w-max p-2'>
                <IoWarningOutline className='w-10 h-10 max-sm:h-8 max-sm:w-8 pb-0.5' />
              </div>
            </div>

            <h2 className='text-lg max-sm:text-base text-black font-semibold mb-2'>{t("warning-checkout-title")}</h2>
            <p className='text-sm max-sm:text-xs px-2'>{t("to-continue-process")}</p>

            <div className='w-full flex justify-center gap-2 font-medium text-sm max-sm:text-xs mt-5'>
              <a href='/register' className='px-3 py-2 bg-yellow-400 hover:bg-yellow-500 text-white rounded-md'>{t("create-acc")}</a>
              <a href='/login' className='px-3 py-2 text-black hover:bg-gray-100 myborder rounded-md'>{t("login-1")}</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
